/*
        ----- Important Notice -----
        This code is the intellectual property of Laxab Digital Solutions Pvt Ltd (Regd company in India,
        hereafter referred as 'company'). If you are not an employee this company, you are not authorized 
        to copy, save, or transmit this code in any form or medium without prior written permission from 
        the company.

        For more information, please visit company's website: www.laxab.com/intellectualproperty

        ----->> app.js  >  root.js  >  body.js  >  bizBody.js

        Version 1.0 : 09 Jun 2023, Created      ***Current version***
*/

// Defaults
import {  useSelector } from "react-redux"
import { useEffect } from "react"

// Components
import Home from "../../businessComponents/body/home"
import Maintenance from "../../businessComponents/body/maintenance"
import Checkout from "../../businessComponents/checkout/checkout"
import UserDetails from "../../businessComponents/checkout/userDetails"
import Profile from "../../businessComponents/settings/profile"
import Login from "../../businessComponents/body/login"
import CartPage from "../../businessComponents/cart/cartPage"
import RegisterUser from "../../businessComponents/body/registerUser"
import Orders from "../../businessComponents/settings/orders"
import Search from "../../businessComponents/body/search"

const BizBody = () =>{

    // Primary Definitions
    const state = useSelector((state)=> state)



    useEffect(()=>{

        window.scrollTo({
            top: 0,
            behavior: 'smooth', // Add smooth scrolling behavior if desired
          });
    },[state.body])

    // Business Methods
    const defineBody = () =>{


        if(state.body === "UserDetails")
            return <UserDetails/>
        else if(state.body === "Home")
            return <Home/>
        else if(state.body === "Maintenance")
            return <Maintenance/>
        else if(state.body === "Checkout")
            return <Checkout/>
        else if(state.body === "Profile")
            return <Profile/>
        else if(state.body === "Orders")
            return <Orders/>
        else if(state.body === "Login")
            return <Login/>
        else if(state.body === "CartPage")
            return <CartPage/>
        else if(state.body === "RegisterUser")
            return <RegisterUser/>
        else if(state.body === "Search")
            return <Search/>
        else
            return <Home/>
    }

    // JSX
    return <div style={{justifySelf:'center',textAlign:'center',width:'100%'}}>
        {defineBody()}
    </div>
}

export default BizBody