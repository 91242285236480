
import Cart from "./cart"
import Banner from "../body/banner"
import { useDispatch } from "react-redux"

const CartPage = () => {

    // Primary components
    const dispatch = useDispatch();

    const navigate = (data) =>{
        dispatch({type:'BACKDROP_ON'})
        dispatch({type:'CHANGE_BODY',payload:data})
    }

    return <div>
        <Banner type="cartPage"/>
        <div style={{display:'flex',justifyContent:'center'}}>
            <div className="stdPadding_2 stdMargin" style={{background:'#fff',}}>

                <Cart/>
                <div className="stdPadding_2" style={{display:'flex'}}>
                    <div onClick={()=>navigate('home')} className="stdButton" style={{marginRight:'10px'}}>Back to Shopping</div>
                    <div onClick={()=>navigate('UserDetails')} className="stdButton">Proceed to Checkout</div>
                </div >
                
            </div>
        </div>

    </div>
}

export default CartPage