/*
        ----- Important Notice -----
        This code is the intellectual property of Laxab Digital Solutions Pvt Ltd (Regd company in India,
        hereafter referred as 'company'). If you are not an employee this company, you are not authorized 
        to copy, save, or transmit this code in any form or medium without prior written permission from 
        the company.

        For more information, please visit company's website: www.laxab.com/intellectualproperty

        ----->> app.js  >  root.js  >  body.js  >  bizBody.js  >  home.js

        Version 1.0 : 09 Jun 2023, Created      ***Current version***
*/

// Defaults
import { useEffect, useState } from "react"
import { useSelector,useDispatch } from "react-redux";

// Components
import fetchData from "../api/api";
import Banner from "./banner";
import ViewCart from "../cart/viewCart";

// Icons
import { HiBadgeCheck } from 'react-icons/hi';
import { BiFoodTag } from 'react-icons/bi'; 

// Others

const Home = () => {

    // Primary Definitions
    const state=useSelector(state=>state)
    const dispatch = useDispatch();
    // Secondary Definitions
    const [products,setProducts] = useState([]);
    const [categories,setcategories] = useState([]);

    // Business Methods
    const addToCart = (data) => {
        const totalCost = data.totalItems * data.mrp;
        const updatedProducts = products.map((item) =>
            item.pid === data.pid && item.did === data.did
            ? { ...item, selected: true, totalMrp: totalCost }
            : item
        );
        setProducts(updatedProducts);
        dispatch({ type: 'CART_ADD', payload: data, count: data.length });
        dispatch({ type: 'SELECTED_ADD', payload: data.id })
    };
    const deleteFromCart = (data) => {
        var counter = state.cartItems.length - 1;
        dispatch({type:'CART_DELETE',payload:data,count:counter})
        setProducts((prevData)=>prevData.map(item => (item.pid === data.pid && item.did === data.did) ? {...item, selected:false} : item ))
        dispatch({ type: 'SELECTED_DEL', payload: data.id })

    }

    // UseEffect
    useEffect(()=>{

        dispatch({type:'BACKDROP_ON'})
        
        const getData = async () => {
            var uri = 'api/v1.0/public/listInventory';
            var body = {"pageNumber": 1,"pageSize": 50};
            const data = await fetchData(uri,body);
            try{
                if (data) {
                    setProducts(data.data.dbData)
                    console.log(data.data.dbData)
                    dispatch({type:'ADD_PRODUCTS',payload:data.data.dbData})
                }
                dispatch({type:'BACKDROP_OFF'})
            }
            catch (error) {
                dispatch({type:'BACKDROP_OFF'})
                dispatch({type:'CHANGE_BODY',payload:'Maintenance'})
            }
        };
        getData();


        const getCatefories = async () => {
            var uri = 'api/v1.0/public/listCategories';
            var body = {"pageNumber": 1,"pageSize": 50};
            const data = await fetchData(uri,body);
            try{
                if (data) {
                    setcategories(data.data.dbData);
                }
                dispatch({type:'BACKDROP_OFF'})
            }
            catch (error) {
                dispatch({type:'BACKDROP_OFF'})
                dispatch({type:'CHANGE_BODY',payload:'Maintenance'})
            }
        };
        getCatefories();


        if (state.banner.category){
            try{
                const pos = document.getElementById(state.banner.category).offsetTop - 130;
                window.scrollTo({
                    top:pos,
                    behavior:'smooth'
                })
                dispatch({type:'CHANGE_CATEGORY',payload:''})

            }
            catch (error) {
                console.log(error)
            }
        }

         // <-- incase of any errors on cart, try enabling this method call

    },[state.banner.category,dispatch])

    return <div>
            
        <Banner type="index"/>

        <div className="stdPadding" >
            
            {
                categories.map((data,index)=>(
                    
                    <div key={index} style={{display:'flex',justifyContent:'center',flexWrap:'wrap'}}>

                        <div className="backbone" style={{flexWrap:'wrap'}}>
                            <div id={data.cid && data.cid.toString()} style={{border:'0px dashed RED',marginTop:'40px',width:'100%',display:'flex',height:'40px',justifyContent:'center'}}>
                                <BiFoodTag style={{display:'flex',margin:'auto 0px',fontSize:'33px',color:'#335553'}}/>
                                <div className="textStyle" style={{display:'flex',margin:'auto 0px',fontSize:'30px',paddingLeft:'5px',letterSpacing:'2px'}}><b>{data.cname}</b></div>
                            </div>
                        </div>
                        

                        <div style={{display:'flex',flexWrap:'wrap',justifyContent: 'center',alignItems: 'center'}}>
                
                            {
                                products.map((product,index)=>{

                                    const isSelected = state.selected.includes(product.id)

                                    if(product.category === data.cname)
                                    {
                                        return  <div key={index} >
                                            {
                                                isSelected
                                                ?
                                                (
                                                    <div className="banner_custom effect5" style={{background:'#ffffff'}} >
                                                        
                                                        <div className="banner_custom_left">
                                                            {
                                                                product.image ?
                                                                <img alt="" src={"https://api.98b870ed6919e4ffdd74527f19020ae9.laxab.com/api/upload_productImages/"+product.image} style={{width:'150px',height:'258px'}}/>
                                                                :
                                                                <img alt="" src="https://api.98b870ed6919e4ffdd74527f19020ae9.laxab.com/api/upload_productImages/image-1689537481715-598110061.png" style={{width:'150px',height:'258px'}}/>
                                                            }
                                                        </div>

                                                        <div >
                    
                                                            <div className="banner_custom_right" style={{position:'relative'}}>
                        
                                                                <div style={{margin:'10px 10px 0px',border:'0px dashed red',paddingBottom:'0',fontSize:'x-large',textAlign:'left'}}>{product.productNameMarathi}</div>
                        
                                                                <div style={{margin:'0px 10px 0px',border:'px dashed red',paddingBotton:'0',paddingTop:'0',fontSize:'normal',textAlign:'right',letterSpacing:'1px'}}>
                                                                    <b>{product.productName}</b>
                                                                </div>
                        
                        
                                                                <div style={{border:'0px solid #ddd',width:'100%',height:'145px',display:'flex',justifyContent:'center'}}>
                                                                    <div style={{display:'flex',margin:'auto 0px',fontSize:'small',letterSpacing:'1px',flexDirection:'column'}}>
                                                                        <div style={{color:'#60b246',fontSize:'75px'}}><HiBadgeCheck/></div>
                                                                        <div>ADDED TO CART</div>
                                                                        <div>Cost: {product.mrp.toFixed(2)} | Qnt: {product.qnt}{product.qntType}</div>
                                                                    </div>
                                                                </div>
                        
                                                                <div onClick={()=>deleteFromCart(product)} className="bannerButton orangeBg" style={{textAlign:'center',position:'absolute',bottom:'0px',left:'0px'}}>
                                                                    <b>Remove</b>
                                                                </div>
                                                            </div>
                                                            
                                                        </div>
                    
                                                    </div>

                                                )
                                                :
                                                (
                                                    <div className="banner_custom effect2" style={{background:'#ffffff'}} >
                                                      
                                                        <div className="banner_custom_left">
                                                            {
                                                                product.image ?
                                                                <img alt="" src={"https://api.98b870ed6919e4ffdd74527f19020ae9.laxab.com/api/upload_productImages/"+product.image} style={{width:'150px',height:'258px'}}/>
                                                                :
                                                                <img alt="" src="https://api.98b870ed6919e4ffdd74527f19020ae9.laxab.com/api/upload_productImages/image-1689537481715-598110061.png" style={{width:'150px',height:'258px'}}/>
                                                            }
                                                            
                                                        </div>

                                                        <div >
                    
                                                            <div className="banner_custom_right" style={{position:'relative'}}>
                        
                                                                <div style={{margin:'10px 10px 0px',border:'0px dashed red',paddingBottom:'0',fontSize:'x-large',textAlign:'left'}}>{product.productNameMarathi}</div>
                        
                                                                <div style={{margin:'0px 10px 0px',border:'px dashed red',paddingBotton:'0',paddingTop:'0',fontSize:'normal',textAlign:'right',letterSpacing:'1px'}}>
                                                                    <b>{product.productName}</b>
                                                                </div>
                        
                                                                <div style={{border:'0px dashed red',display:'flex',alignItems:'center',justifyContent:'center',padding:'10px 0px',fontSize:'small',letterSpacing:'2px'}}>
                                                                    <div className="bannerLabels">QUANTITY: {product.qnt}{product.qntType}</div>
                                                                </div>
                                                        
                                                                {
                                                                    product.nonDiscountMrp-product.mrp > 0 &&
                                                                    <div>
                                                                        <div style={{width:'100%',display:'flex',justifyContent:'center',border:'0px dashed green',lineHeight:'10px'}}>
                                                                            <div style={{display:'flex',color:'#999',padding:'5px',margin:'auto 0px',fontSize:'small',letterSpacing:'3px'}}>
                                                                                DISCOUNT
                                                                            </div>
                                                                        </div>
                                                                        <div style={{width:'100%',display:'flex',justifyContent:'center',border:'0px dashed green',lineHeight:'6px'}}>
                                                                            <div style={{display:'flex',color:'#999',fontSize:'small',padding:'5px',margin:'auto 0px',textDecoration: 'line-through',letterSpacing:'1px'}}>
                                                                                ₹{product.nonDiscountMrp.toFixed(2)}
                                                                            </div>
                                                                            <div style={{display:'flex',padding:'7px 0px 7px',color:'#F37512',fontSize:'small'}}>
                                                                                <b>₹{((product.nonDiscountMrp-product.mrp)).toFixed(2)} OFF</b>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                }


                                                                <div style={{display:'flex',justifyContent:'center',border:'0px dashed #dddddd',width:'100%',position:'absolute',bottom:'60px',left:'0px',height:'50px'}}>
                                                                    <div style={{display:'flex',margin:'auto 0px',borderRadius:'20px',border:'1px dashed #ccc',padding:'5px 10px 5px',color:'#60b246'}}>
                                                                        <div style={{marginLeft:'0px',fontSize:'14px',paddingTop:'2px'}}>₹</div>
                                                                        <div style={{marginLeft:'0px'}}><b style={{fontSize:'25px'}}>{product.mrp.toFixed(2)}</b></div>
                                                                    </div>
                                                                </div>
                        
                                                                <div onClick={()=>addToCart(product)} className="bannerButton" style={{textAlign:'center',position:'absolute',bottom:'0px',left:'0px'}}>
                                                                    <b>Add to Cart </b>
                                                                </div>

                                                            </div>
                                                        </div>
                    
                                                    </div>

                                                )
                                            }

                                        </div>
                                    }
                                    return null
                                })
                            }

                        </div>

                    </div>
                ))
            }


            <ViewCart/>

            {
                /*<pre style={{textAlign:'left'}}>{JSON.stringify(state.products,null,2)}</pre>*/
            }

        </div>

    </div>
}

export default Home