/*
        ----- Important Notice -----
        This code is the intellectual property of Laxab Digital Solutions Pvt Ltd (Regd company in India,
        hereafter referred as 'company'). If you are not an employee this company, you are not authorized 
        to copy, save, or transmit this code in any form or medium without prior written permission from 
        the company.

        For more information, please visit company's website: www.laxab.com/intellectualproperty

        ----->> Header <<-----

        Version 1.0 : 09 Jun 2023, Created      ***Current version***
*/

// Defaults
import { useSelector, useDispatch } from "react-redux"

// Icons
import { CgShoppingCart } from 'react-icons/cg';
import { CiDiscount1 } from 'react-icons/ci';
import { HiOutlineUserCircle } from 'react-icons/hi';
import { BsSearch } from 'react-icons/bs';
import { useEffect, useState } from "react";

import fetchData from "../../businessComponents/api/api";

const Header = () => {

    // Primary Definitions
    const dispatch = useDispatch();
    const cartCount = useSelector(state => state.cartCount)
    const state = useSelector(state => state)
    
    // Secondary Definitions
    const [child,setchild] = useState("topmenuoptionshide")
    const [c,setc] = useState()

    // Business Methods
    const showchild = () =>{
        setchild("topmenuoptionsshow")
    }
    const hidechild = () =>{
        setchild("topmenuoptionshide")
    }
    const navigate = (data) =>{
        //dispatch({type:'BACKDROP_ON'})
        if(data==="Logout"){
            dispatch({type:'LOGOFF'})
            dispatch({type:'CHANGE_BODY',payload:data})
            dispatch({type:'DIALOG_ON',title:'Logout',body:'You are successfully logged out'})
        }
        else
            dispatch({type:'CHANGE_BODY',payload:data})
    }
    useEffect(()=>{

        const updateData = async () =>{
            const uri='api/be/v1.0/inventory/getlogo';
            const body={}

            const data = await fetchData(uri,body)
            try{
                setc(data)
            }
            catch (error){
            }
        }
        updateData()

    },[])

    return <div className="topMenuBackbone">

        <div className="topMenu " >

            <div className="backbone">

                <div onClick={()=>navigate('home')} className="topMenu_left" style={{cursor:'pointer'}}><h2>
                    {
                        c && c.data && c.data.dbData && c.data.dbData.length!==0 &&
                        <img alt="" src={"https://api.98b870ed6919e4ffdd74527f19020ae9.laxab.com/api/upload_backgrounds/"+c.data.dbData[0].logo} height={c.data.dbData[0].height+'px'}/>
                    }
                    </h2></div>

                <div className="topMenu_right">
                    <div onClick={()=>navigate('Search')} className="topMenuButton"><BsSearch style={{paddingRight:'5px',fontSize:'x-large'}}/>Search</div>
                    <div onClick={()=>navigate('Offers')} className="topMenuButton"><CiDiscount1 style={{paddingRight:'5px',fontSize:'x-large'}}/>Offers</div>
                    
                    {
                        cartCount===0
                            ?
                            <div onClick={()=>navigate('Cart-No')} className="topMenuButton"> <CgShoppingCart style={{paddingRight:'5px',fontSize:'x-large'}}/> Cart</div>
                            :
                            (
                                <div onClick={()=>navigate('CartPage')} className="topMenuButton" style={{height:'auto'}}> 
                                    
                                    <div className="backgroundGreen" style={{padding:'0px 10px 0px',height:'25px',fontSize:'small',borderRadius:'0px',borderTopLeftRadius:'10px',borderTopRightRadius:'10px'}} >
                                        {cartCount}
                                    </div>
                                    <div style={{paddingLeft:'10px'}}>
                                        Cart 
                                    </div>

                                </div>
                            )
                            
                    }
                    {
                        state.loginStatus
                        ?
                        <div>
                            <div onClick={()=>navigate('Login')} onMouseEnter={()=>showchild()} onMouseLeave={()=>hidechild()} className="topMenuButton">
                                <HiOutlineUserCircle style={{paddingRight:'5px',fontSize:'x-large'}}/>{state.loginData.name && state.loginData.name}
                            </div>
                            
                            <div className={child} onMouseEnter={()=>showchild()} onMouseLeave={()=>hidechild()}  style={{marginLeft:'30px', width:'auto', position:'absolute', top:'60px', background:'#fff', flexDirection:'column', height:'auto',justifyContent:'flex-start'}}>
                                <div onClick={()=>navigate('Profile')} className="topMenuButton" style={{padding:'15px 40px 10px 0px'}}>Profile Settings</div>
                                <div onClick={()=>navigate('Orders')} className="topMenuButton" style={{padding:'0px 40px 10px 0px'}}>Orders</div>
                                <div onClick={()=>navigate('Logout')} className="topMenuButton" style={{padding:'0px 40px 10px 0px'}}>Logout</div>
                            </div>

                        </div>
                        :
                        <div onClick={()=>navigate('Login')} className="topMenuButton">
                            <HiOutlineUserCircle style={{paddingRight:'5px',fontSize:'x-large'}}/>Login
                        </div>

                    }
                    
                </div>

            </div>

        </div>
        
    </div>
}

export default Header