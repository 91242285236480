/*
        ----- Important Notice -----
        This code is the intellectual property of Laxab Digital Solutions Pvt Ltd (Regd company in India,
        hereafter referred as 'company'). If you are not an employee this company, you are not authorized 
        to copy, save, or transmit this code in any form or medium without prior written permission from 
        the company.

        For more information, please visit company's website: www.laxab.com/intellectualproperty

        ----->> app.js  >  root.js  >  body.js  >  bizBody.js  >  home.js  >  cart.js

        Version 1.0 : 09 Jun 2023, Created      ***Current version***
*/

// Defaults
import { useEffect, useState } from "react";

// Icons 
import { RiDeleteBin6Line } from 'react-icons/ri';
import { BsCartX } from 'react-icons/bs';

// Redux
const { useSelector, useDispatch } = require("react-redux")

const Cart = () =>{

    // Primary Definitions
    const state     = useSelector((state)=>state)
    const dispatch  = useDispatch()

    // Secondary Definitions
    const [cart,setCart]    = useState([])
    const [totalMrp,settotalMrp]= useState(0.0)

    useEffect(()=>{

        dispatch({type:'BACKDROP_OFF'})

        setCart(state.cartItems)

        if (state.cartItems.length!==0){
            settotalMrp(state.cartItems.reduce((total, item) => total + item.totalMrp, 0))
            dispatch({type:'TOTAL_AMOUNT',payload:state.cartItems.reduce((total, item) => total + item.totalMrp, 0)})
            var grandtotal = parseInt(state.pindeliverycharge) + parseInt(state.cartItems.reduce((total, item) => total + item.totalMrp, 0))
            dispatch({type:'GRAND_TOTAL',payload:parseInt(grandtotal.toFixed(2))})
        }
        else{
            settotalMrp(0)
            dispatch({type:'TOTAL_AMOUNT',payload:0})
        }

    },[state.cartItems,dispatch,state.pindeliverycharge])


    // Business Methods
    const deleteItem = (data) =>{
        var counter = state.cartItems.length - 1;
        dispatch({type:'CART_DELETE',payload:data,count:counter})
        dispatch({ type: 'SELECTED_DEL', payload: data.id })
        console.log(totalMrp)
    }
    const updateTotal = (data,op) =>{
        var totalItems=0;
        var totalMrp=0
        if(op==="+"){
            totalItems = data.totalItems + 1;
            totalMrp = totalItems*data.mrp;
            dispatch({type:'CART_ITEMSUPDATE',payload:data,totalItems:totalItems,totalMrp:totalMrp})
        }
        else{
            totalItems = data.totalItems - 1;
            totalMrp = totalItems*data.mrp;
            if (totalItems <= 0){
                var counter = state.cartItems.length - 1;
                dispatch({type:'CART_DELETE',payload:data,count:counter})
                dispatch({ type: 'SELECTED_DEL', payload: data.id })
            }
            else
                dispatch({type:'CART_ITEMSUPDATE',payload:data,totalItems:totalItems,totalMrp:totalMrp})
        }
    }
    const redirect=()=>{
        dispatch({type:'BACKDROP_ON'})
        dispatch({type:"CHANGE_BODY",payload:"Home"})
    }

    return <div className="newzIndex" style={{background:'white',width:'770px',textAlign:'center',justifyContent:'flex-start',padding:'15px 15px 0px',marginBottom:'0px'}}>
        
        {
            cart.length!==0
            &&
            <div className="stdRow"> 

                <div className="stdRowElements" style={{width:'calc(55% - 30px)',textAlign:'left',fontWeight:'bold',justifyContent:'flex-start'}}>
                    <div style={{paddingLeft:'20px'}}>Product</div>
                </div>
                <div className="stdRowElements" style={{width:'calc(15% - 20px)'}}>Items</div>
                <div className="stdRowElements" style={{width:'calc(15% - 20px)'}}>MRP ₹</div>
                <div className="stdRowElements" style={{width:'calc(15% - 20px)'}}>Total ₹</div>

            </div>
        }
        {
            cart.length!==0
            ?
            cart.map((data,index)=>(

                <div key={index} className="stdRow"> 

                    <div className="stdRowElements" style={{width:'calc(55% - 30px)',textAlign:'left',fontWeight:'bold',justifyContent:'flex-start'}}>
                        
                        <div onClick={()=>deleteItem(data)} className="deleteButton" style={{display:'flex',alignItems:'center',marginTop:'auto',marginBottom:'auto'}}><RiDeleteBin6Line/></div>
                        <div style={{display:'flex',alignItems:'center',marginTop:'auto',marginBottom:'auto'}}>{data.productNameMarathi} / {data.productName}, Quantity: {data.qnt} </div>
                    
                    </div>

                    <div className="stdRowElements" style={{width:'calc(15% - 20px)'}}>

                        <div onClick={()=>updateTotal(data,'-')} className="addCartOrange" >-</div>
                        <div className="addCartText">{data.totalItems}</div>
                        <div onClick={()=>updateTotal(data,'+')} className="addCartGreen">+</div>

                    </div>

                    <div className="stdRowElements" style={{width:'calc(15% - 20px)'}}>₹ {data.mrp}</div>
                    <div className="stdRowElements" style={{width:'calc(15% - 20px)'}}>₹ {data.totalMrp && data.totalMrp.toFixed(2)}</div>

                </div>

            ))
            :
            <div style={{color:'#888888',fontSize:'20px',display:'flex',flexDirection:'column',justifyContent:'center',textAlign:'center'}}>

                <BsCartX style={{fontSize:'120px',alignSelf:'center',padding:'30px'}}/>
                <div>No Items in Cart</div>
                <div className="stdButton" onClick={()=>redirect()} style={{width:'250px',margin:'10px auto 20px'}}>Resume Shopping</div>

            </div>
        }
        {
            cart.length!==0
            &&
            <div className="stdRow" style={{marginBottom:'0px'}}> 

                <div className="stdRowElements" style={{width:'calc(30% - 40px)'}}></div>
                <div className="stdRowElements" style={{width:'calc(0% - 50px)'}}></div>
                <div className="stdRowElements" style={{width:'calc(60% - 0px)',justifyContent:'flex-end',fontWeight:'normal'}}>Order Total</div>
                <div className="stdRowElements" style={{width:'calc(20% - 30px)',fontWeight:'bold'}}> ₹ {state.totalAmount.toFixed(2)}</div>

            </div>
        }
        {
            /*<pre style={{textAlign:'left'}}>{JSON.stringify(state.cartItems,null,2)}</pre>*/
        }
    </div>
}

export default Cart