/*
        ----- Important Notice -----
        This code is the intellectual property of Laxab Digital Solutions Pvt Ltd (Regd company in India,
        hereafter referred as 'company'). If you are not an employee this company, you are not authorized 
        to copy, save, or transmit this code in any form or medium without prior written permission from 
        the company.

        For more information, please visit company's website: www.laxab.com/intellectualproperty

        ----->> app.js  >  root.js  >  body.js  >  bizBody.js  >  home.js  >  banner.js

        Version 1.0 : 09 Jun 2023, Created      ***Current version***
*/

// Defaults
import { useDispatch } from "react-redux";

// Icons
import { MdDeliveryDining } from 'react-icons/md';
import { BsEyeglasses, BsFillCartCheckFill } from 'react-icons/bs';
import { useEffect, useState } from "react";

// Components
import fetchData from "../api/api";

const Banner = (props) => {

    // Primary Definitions
    const dispatch = useDispatch();

    // Secondary Definitions
    const [categories,setcategories] = useState([])

    // Business Methods
    const handleClick = (data) =>{
        dispatch({type:'CHANGE_CATEGORY',payload:data.toString()})
    }

    // UseEffect
    useEffect(()=>{
        const getCatefories = async () => {
            var uri = 'api/v1.0/public/listCategories';
            var body = {"pageNumber": 1,"pageSize": 5};
            const data = await fetchData(uri,body);
            try{
                if (data) {
                    setcategories(data.data.dbData);
                }
                dispatch({type:'BACKDROP_OFF'})
            }
            catch (error) {
            }
        };
        getCatefories();},[dispatch])



    // JSX
    return <div className="bannerCanvas">

        <div className="bannerCanvas" style={{position:'relative',left:'0px'}}>

            <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>

                {
                    props.type==="index"
                    &&
                    <div className="backbone" style={{justifyContent:'center',alignItems:'center'}}>
                        {
                            categories.map((data,index)=>(
                                <div>
                                    {
                                        data.imageUrl ?
                                        <div key={index} onClick={()=>handleClick(data.cid.toString())} style={{
                                            background:`url('https://api.98b870ed6919e4ffdd74527f19020ae9.laxab.com/api/upload_categoryImages/${data.imageUrl}')no-repeat`,
                                            backgroundSize:"100% 100%"
                                        }} className="banner_insights">
                                            <div style={{display:'flex',margin:'auto 0px',width:'calc(100% - 40px)',background:'#ffffffaa',padding:'10px 20px',fontSize:'25px',letterSpacing:'1px'}}>
                                                <b>{data.cname}</b>
                                            </div>
                                        </div> 
                                        :
                                        <div key={index} onClick={()=>handleClick(data.cid.toString())} style={{
                                            background:"url('https://api.98b870ed6919e4ffdd74527f19020ae9.laxab.com/api/getimage/image-1689548385256-707499350.webp')no-repeat",
                                            backgroundSize:"100% 100%"
                                        }} className="banner_insights">
                                            <div style={{display:'flex',margin:'auto 0px',width:'calc(100% - 40px)',background:'#ffffffaa',padding:'10px 20px',fontSize:'25px',letterSpacing:'1px'}}>
                                                <b>{data.cname}</b>
                                            </div>
                                        </div>
                                    }
                                </div>
                            ))
                        }
                    </div>
                }

                {
                    props.type==="checkout"
                    &&
                    <div className="backbone" style={{justifyContent:'center',alignItems:'center',color:'white',padding:'20px 0px 20px', flexDirection:'row'}}>
                        
                        <div style={{width:'auto',height:'auto',fontSize:'120px',marginTop:'10px',paddingRight:'30px', border:'0px dashed #cccccc',justifyContent:'center',alignContent:'center',textAlign:'center',display:'flex'}}>
                            <MdDeliveryDining/>
                        </div>

                        <div style={{width:'auto',height:'auto',margin:'0px',border:'0px dashed #cccccc',flexDirection:'column',justifyContent:'center'}}>
                            <h1><b>Allrighty!!  Your Delivery details please,</b></h1>
                            <div style={{padding:'10px 0px 0px', fontSize:'x-large'}}>कृपया डिलिव्हरी पत्ता आणि संपर्क तपशील कळवा</div>
                        </div>

                    </div>
                }

                {
                    props.type==="billing"
                    &&
                    <div className="backbone" style={{justifyContent:'center',alignItems:'center',color:'white',padding:'20px 0px 20px', flexDirection:'row'}}>
                        
                        <div style={{width:'auto',height:'auto',fontSize:'120px',marginTop:'10px',paddingRight:'30px', border:'0px dashed #cccccc',justifyContent:'center',alignContent:'center',textAlign:'center',display:'flex'}}>
                            <BsEyeglasses/>
                        </div>

                        <div style={{width:'auto',height:'auto',margin:'0px',border:'0px dashed #cccccc',flexDirection:'column',justifyContent:'center'}}>
                            <h1><b>Check your order</b></h1>
                            <div style={{padding:'10px 0px 0px', fontSize:'x-large'}}>आपली ऑर्डर तपासा</div>
                        </div>

                    </div>
                }

                {
                    props.type==="cartPage"
                    &&
                    <div className="backbone" style={{justifyContent:'center',alignItems:'center',color:'white',padding:'20px 0px 20px', flexDirection:'row'}}>
                        
                        <div style={{width:'auto',height:'auto',fontSize:'80px',margin:'10px 0px 10px',paddingRight:'30px', border:'0px dashed #cccccc',justifyContent:'center',alignContent:'center',textAlign:'center',display:'flex'}}>
                            <BsFillCartCheckFill/>
                        </div>

                        <div style={{width:'auto',height:'auto',margin:'0px',border:'0px dashed #cccccc',flexDirection:'column',justifyContent:'center'}}>
                            <h1><b>Your Cart</b></h1>
                        </div>

                    </div>
                }

            </div>

        </div>
        
    </div>
}

export default Banner