import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import fetchData from "../api/api";

// Icons
import { FiPackage, FiTruck, FiUser, FiHash, FiFlag, FiSmile, FiDollarSign } from 'react-icons/fi';
import { IoIosTimer } from 'react-icons/io';

import Rating from '@mui/material/Rating';



const Orders = () =>{

    // Primary Definitions
    const dispatch = useDispatch();
    const state = useSelector(state => state)

    // Secondary Definitions
    const [a,seta] = useState("");
    const [b,setb] = useState("")
    const [rating,setrating] = useState([0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0])
    const [viewfeedback,setviewfeedback] = useState([])

    useEffect(()=>{

        dispatch({type:'BACKDROP_ON'})
        const getData = async () => {
            var uri = 'api/v1.0/public/listorders';
            var body = {
                "login":state.loginData.mobile,
                "session":state.loginData.session
            };
            const data = await fetchData(uri,body);
            try{
                if (data.data!=="Incorrect Session") {
                    seta(data.data.dbData)
                }
                else{
                    dispatch({type:'LOGOFF'})
                    dispatch({type:'CHANGE_BODY',payload:'Login'})
                }

                dispatch({type:'BACKDROP_OFF'})
            }
            catch (error) {
                dispatch({type:'BACKDROP_OFF'})
                dispatch({type:'CHANGE_BODY',payload:'Maintenance'})
            }
        };
        getData();

    },[dispatch,state.loginData])


    const iconDisplay = (title) =>{
        if(title==="Order Placed by Customer")
            return <FiDollarSign style={{display:'flex',margin:'auto 0px auto'}}/>
        else if(title==="We are working on your order")
            return <FiSmile style={{display:'flex',margin:'auto 0px auto'}}/>
        else if(title==="Delivery Initiated")
            return <FiTruck style={{display:'flex',margin:'auto 0px auto'}}/>
        else if(title==="Delivery Confirmed!")
            return <FiFlag style={{display:'flex',margin:'auto 0px auto'}}/>
        else
            return <IoIosTimer style={{display:'flex',margin:'auto 0px auto'}}/>
    }
    const icon = (item) =>{
        if(item.orderStage==="Order Placed"){
            // BLACK
            return (
                <div style={{
                    background:"linear-gradient(195deg, rgb(66, 66, 74), rgb(25, 25, 25))",
                    boxShadow:'rgba(0, 0, 0, 0.14) 0rem 0.25rem 1.25rem 0rem, rgba(64, 64, 64, 0.4) 0rem 0.4375rem 0.625rem -0.3125rem',
                    color:'#fff',
                    display:'flex',alignItems:'center',margin:'auto 15px auto',width:'65px',height:'50px',borderRadius:'5px',justifyContent:'center'}}
                >
                    <b style={{fontSize:'28px',marginTop:'5px'}}><FiPackage/></b>
                </div>
            )
        }
        else if(item.orderStage==="Delivered"){
            // GREEN
            return (
                <div style={{
                    background:'linear-gradient(195deg, rgb(102, 187, 106), rgb(67, 160, 71))',
                    boxShadow:'rgba(0, 0, 0, 0.14) 0rem 0.25rem 1.25rem 0rem, rgba(76, 175, 79, 0.4) 0rem 0.4375rem 0.625rem -0.3125rem',
                    color:'#fff',
                    display:'flex',alignItems:'center',margin:'auto 15px auto',width:'65px',height:'50px',borderRadius:'5px',justifyContent:'center'}}
                >
                    <b style={{fontSize:'28px',marginTop:'5px'}}><FiPackage/></b>
                </div>
            )
        }
        else if(item.orderStage==="Canceled"){
            // PINK
            return (
                <div style={{
                    background:'linear-gradient(195deg, rgb(236, 64, 122), rgb(216, 27, 96))',
                    boxShadow:'rgba(0, 0, 0, 0.14) 0rem 0.25rem 1.25rem 0rem, rgba(76, 175, 79, 0.4) 0rem 0.4375rem 0.625rem -0.3125rem',
                    color:'#fff',
                    display:'flex',alignItems:'center',margin:'auto 15px auto',width:'65px',height:'50px',borderRadius:'5px',justifyContent:'center'}}
                >
                    <b style={{fontSize:'28px',marginTop:'5px'}}><FiPackage/></b>
                </div>
            )
        }
        else if(item.orderStage==="In Transit"){
            // BLUE
            return (
                <div style={{
                    background:'linear-gradient(195deg, rgb(73, 163, 241), rgb(26, 115, 232))',
                    boxShadow:'rgba(0, 0, 0, 0.14) 0rem 0.25rem 1.25rem 0rem, rgba(76, 175, 79, 0.4) 0rem 0.4375rem 0.625rem -0.3125rem',
                    color:'#fff',
                    display:'flex',alignItems:'center',margin:'auto 15px auto',width:'65px',height:'50px',borderRadius:'5px',justifyContent:'center'}}
                >
                    <b style={{fontSize:'28px',marginTop:'5px'}}><FiPackage/></b>
                </div>
            )
        }
        else if(item.orderStage==="Work In Progress"){
            // AMBER
            return (
                <div style={{
                    background:'linear-gradient(195deg, #FF8D05, #DF7B03)',
                    boxShadow:'rgba(0, 0, 0, 0.14) 0rem 0.25rem 1.25rem 0rem, rgba(76, 175, 79, 0.4) 0rem 0.4375rem 0.625rem -0.3125rem',
                    color:'#fff',
                    display:'flex',alignItems:'center',margin:'auto 15px auto',width:'65px',height:'50px',borderRadius:'5px',justifyContent:'center'}}
                >
                    <b style={{fontSize:'28px',marginTop:'5px'}}><FiPackage/></b>
                </div>
            )
        }
        else{
            // GREEN
            return (
                <div style={{
                    background:'linear-gradient(195deg, rgb(102, 187, 106), rgb(67, 160, 71))',
                    boxShadow:'rgba(0, 0, 0, 0.14) 0rem 0.25rem 1.25rem 0rem, rgba(76, 175, 79, 0.4) 0rem 0.4375rem 0.625rem -0.3125rem',
                    color:'#fff',
                    display:'flex',alignItems:'center',margin:'auto 15px auto',width:'65px',height:'50px',borderRadius:'5px',justifyContent:'center'}}
                >
                    <b style={{fontSize:'28px',marginTop:'5px'}}><FiPackage/></b>
                </div>
            )
        }
    }
    const clickAction = (order) =>{
        dispatch({type:'BACKDROP_ON'})
        const getData = async () => {
            var uri = 'api/v1.0/public/vieworder';
            var body = {
                "login":state.loginData.mobile,
                "session":state.loginData.session,
                "order":order
            };
            const data = await fetchData(uri,body);
            try{
                if (data.data!=="Incorrect Session") {
                    setb(data.data)
                }
                else{
                    dispatch({type:'LOGOFF'})
                    dispatch({type:'CHANGE_BODY',payload:'Login'})
                }

                dispatch({type:'BACKDROP_OFF'})
            }
            catch (error) {
                dispatch({type:'BACKDROP_OFF'})
                dispatch({type:'CHANGE_BODY',payload:'Maintenance'})
            }
        };
        getData();

    }
    const orderTracking = (state, title, body) =>{
        if(state)
            return(
                <div style={{margin:'10px 0px 0px',width:'800px'}}>
                    <div style={{background:'#fff',padding:'20px 200px 20px',display:'flex'}}>
                        <div style={{
                            width:'90px',height:'70px',display:'flex',justifyContent:'center',borderRadius:'10px',color:'#fff',fontSize:'30px',
                            background:'linear-gradient(195deg, rgb(102, 187, 106), rgb(67, 160, 71))',
                            boxShadow:'rgba(0, 0, 0, 0.14) 0rem 0.25rem 1.25rem 0rem, rgba(76, 175, 79, 0.4) 0rem 0.4375rem 0.625rem -0.3125rem',
                        }}>
                            {iconDisplay(title)}
                        </div>
                        <div style={{width:'calc(100% - 80px)',height:'auto',display:'flex',margin:'auto 0px auto 20px',justifyContent:'left'}}>
                            <div style={{display:'flex',margin:'auto 0px auto',flexDirection:'column',textAlign:'left'}}>
                                <b>{title}</b>
                                <div style={{color:"#999"}}>{body}</div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        else
            return (
                <div style={{margin:'10px 0px 0px',width:'800px'}}>
                    <div style={{background:'#fff',padding:'20px 200px 20px',display:'flex'}}>
                        <div style={{width:'90px',height:'70px',background:'#eee',display:'flex',justifyContent:'center',borderRadius:'10px',color:'#888',fontSize:'30px'}}>
                            {iconDisplay(title)}    
                        </div>
                        <div style={{width:'calc(100% - 80px)',height:'70px',display:'flex',margin:'auto 0px auto 20px',justifyContent:'left'}}>
                            <div style={{display:'flex',margin:'auto 0px auto', color:'#aaa',flexDirection:'column',textAlign:'left'}}>
                                <b>{title}</b>
                                {body}
                            </div>
                        </div>
                    </div>
                </div>
            )
    }
    const orderStageCheck = (orderTimeline, stage) =>{
        return orderTimeline.some(item => item.stage === stage);
    }
    const cstyle1={width:'25%',border:'0px dashed #888',height:'250px',display:'flex',margin:'50px 10px 0px',background:'#fff',
    borderRadius:'8px',boxShadow:'rgba(0, 0, 0, 0.14) 0rem 0.25rem 1.25rem 0rem, #666 0rem 0.4375rem 0.625rem -0.3125rem',padding:'15px'}

    const box1={
        background:'linear-gradient(195deg, rgb(102, 187, 106), rgb(67, 160, 71))',
        boxShadow:'rgba(0, 0, 0, 0.14) 0rem 0.25rem 1.25rem 0rem, rgba(76, 175, 79, 0.4) 0rem 0.4375rem 0.625rem -0.3125rem',
        width:'90px',height:'80px',borderRadius:'15px',position:'relative',top:'-40px',left:'180px',
        fontSize:'50px',color:'#fff',display:'flex',justifyContent:'center'
    }
    const box2={
        background:'linear-gradient(195deg, rgb(73, 163, 241), rgb(26, 115, 232))',
        boxShadow:'rgba(0, 0, 0, 0.14) 0rem 0.25rem 1.25rem 0rem, rgba(76, 175, 79, 0.4) 0rem 0.4375rem 0.625rem -0.3125rem',
        width:'90px',height:'80px',borderRadius:'15px',position:'relative',top:'-40px',left:'180px',
        fontSize:'50px',color:'#fff',display:'flex',justifyContent:'center'
    }
    const box3={
        background:'linear-gradient(195deg, rgb(236, 64, 122), rgb(216, 27, 96))',
        boxShadow:'rgba(0, 0, 0, 0.14) 0rem 0.25rem 1.25rem 0rem, rgba(76, 175, 79, 0.4) 0rem 0.4375rem 0.625rem -0.3125rem',
        width:'90px',height:'80px',borderRadius:'15px',position:'relative',top:'-40px',left:'180px',
        fontSize:'50px',color:'#fff',display:'flex',justifyContent:'center'
    }
    const updateRating = (i, value, rating) => {
        const data = [...rating]; // Create a copy of the rating array
        data[i] = value; // Update the value at index i
        setrating(data); // Update the rating state with the modified array
      }
    const [sentFeedback,setsentFeedback] = useState(0)
    const submitFeedback = () =>{

        dispatch({type:'BACKDROP_ON'})
        const getData = async () => {
            var uri = 'api/v1.0/public/orderfeedback';
            var body = [
                    {
                        "type": "service",
                        "feedbackname": "website",
                        "feedback": rating[0],
                        "orderId": b.customerDetails_and_amount.orderId,
                        "name": b.customerDetails_and_amount.name,
                        "mobile": b.customerDetails_and_amount.mobile,
                        "email": b.customerDetails_and_amount.email
                    },
                    {
                        "type": "service",
                        "feedbackname": "delivery",
                        "feedback": rating[1],
                        "orderId": b.customerDetails_and_amount.orderId,
                        "name": b.customerDetails_and_amount.name,
                        "mobile": b.customerDetails_and_amount.mobile,
                        "email": b.customerDetails_and_amount.email
                    }
                ];
            for (var i=0; i<b.orderItems.length; i++){
                var productBody = {
                    "type": "product",
                    "feedbackname": b.orderItems[i].productName,
                    "feedback": rating[i+2],
                    "orderId": b.customerDetails_and_amount.orderId,
                    "name": b.customerDetails_and_amount.name,
                    "mobile": b.customerDetails_and_amount.mobile,
                    "email": b.customerDetails_and_amount.email
                }
                body[i+2] = productBody;
            }
            const data = await fetchData(uri,body);
            try{
                dispatch({type:'BACKDROP_OFF'})
                console.log(data)
                dispatch({type:'DIALOG_ON',title:'Feedback',body:'Thanks for the feedback, it does help us on improving to serve you better!'})
                setsentFeedback(1);
            }
            catch (error) {
                dispatch({type:'BACKDROP_OFF'})
                dispatch({type:'CHANGE_BODY',payload:'Maintenance'})
            }
        };
        getData();

    }
    useEffect(()=>{

        setrating([0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0])
        dispatch({type:'BACKDROP_ON'})

        const getFeedbackData = async (orderId) => {
            var uri = 'api/v1.0/public/viewfeedback';
            var body = {
                "orderId": orderId
            }
            const data = await fetchData(uri,body);
            try{
                dispatch({type:'BACKDROP_OFF'})
                setviewfeedback(data)
                setsentFeedback(0);
            }
            catch (error) {
                dispatch({type:'BACKDROP_OFF'})
                dispatch({type:'CHANGE_BODY',payload:'Maintenance'})
            }
        }
        var orderId2 = 0
        if(b.customerDetails_and_amount)
            orderId2 = b.customerDetails_and_amount.orderId

        getFeedbackData(orderId2)

    },[b.customerDetails_and_amount,sentFeedback,dispatch])
      

    return <div style={{display:'flex',height:'calc(100vh - 100px)'}}>

        <div style={{background:'#fff',width:'300px',borderRight:'1px solid #F3F1F3',overflowX:'auto'}}>


            {
                a &&
                a.map((item,index)=>(
                    <div key={index} onClick={()=>clickAction(item.orderId)} className="listedButton">
                        {icon(item)}
                        <div 
                            style={{
                            display:'flex',margin:'auto 0px auto',flexDirection:'column',
                            width:'calc(100% - 110px)'
                        }}>
                            <div style={{display:'flex',alignItems:'end',border:'0px dashed #ccc',width:'100%',justifyContent:'flex-start'}}>
                                <b>Order ID: {item.orderId}</b>
                            </div>
                            <div style={{display:'flex',alignItems:'end',border:'0px dashed #ccc',fontSize:'small',width:'100%',justifyContent:'flex-end'}}>
                                <div style={{background:'#FBFDB0',padding:'3px 8px 3px',borderRadius:'5px',marginTop:'5px'}}>
                                    {item.area.length>15 ? item.area.substring(0, 14)+'..., '+item.district : item.area+", "+item.district}
                                </div>
                            </div>
                        </div>
                    </div>
                ))
            }

        </div>
        <div style={{width:'calc(100% - 301px)',overflow:'scroll',background:'#ffffffb6',alignContent:'center',textAlign:'center',alignItems:'center'}}>
            
            {
                b.customerDetails_and_amount
                &&
                <div>
                    <div style={{margin:'30px 0px 0px',padding:'10px',borderRadius:'8px'}}>
                        {

                            orderStageCheck(b.orderTimeline,4)
                            ?
                            <h2>Your order has been delivered</h2>
                            :
                            <div>Order ID {b.customerDetails_and_amount.orderId} Status <h2>{b.customerDetails_and_amount.orderStage}</h2></div>
                        }
                    </div>
                    {

                        orderStageCheck(b.orderTimeline,4)
                        &&
                        viewfeedback.data
                        &&
                        viewfeedback.data.dbData
                        &&
                        viewfeedback.data.dbData.length > 0
                        &&
                        <div style={{display:'flex',justifyContent:'center',margin:'30px 0px 50px'}}>
                            <div style={{margin:'0px 0px 10px',padding:'20px 50px 20px',borderRadius:'8px',width:'500px',
                                background:"linear-gradient(195deg, rgb(66, 66, 74), rgb(25, 25, 25))",
                                boxShadow:'rgba(0, 0, 0, 0.14) 0rem 0.25rem 1.25rem 0rem, rgba(64, 64, 64, 0.4) 0rem 0.4375rem 0.625rem -0.3125rem',
                                color:'#fff', textAlign:'center', fontSize:'large', letterSpacing:'1px', lineHeight:'28px'
                            }}>
                            <div style={{border:'0px dashed RED',background:'#4F4E4E',borderRadius:'5px'}}>Thanks for your feedback!</div>
                                <div style={{border:'0px dashed RED',textAlign:'left',padding:'20px 0px 0px',display:'flex'}}>
                                    <div style={{width:'70%'}}>Our Website's Experience</div>
                                    <div style={{width:'30%',textAlign:'right'}}>
                                        <Rating name="read-only" value={viewfeedback.data.dbData[0].feedback} readOnly/>
                                    </div>
                                </div>
                                <div style={{border:'0px dashed RED',textAlign:'left',padding:'20px 0px 20px',display:'flex'}}>
                                    <div style={{width:'70%'}}>Delivery Service</div>
                                    <div style={{width:'30%',textAlign:'right'}}>
                                        <Rating name="read-only" value={viewfeedback.data.dbData[1].feedback} readOnly/>
                                    </div>
                                </div>
                                {
                                    b.orderItems.map((items,index)=>(
                                        <div key={index} style={{border:'0px dashed RED',textAlign:'left',padding:'20px 0px 0px',display:'flex'}}>
                                            <div style={{width:'70%'}}>{items.productNameMarathi} / {items.productName}</div>
                                            <div style={{width:'30%',textAlign:'right'}}>
                                                <Rating name="read-only" value={viewfeedback.data.dbData[index+2].feedback} readOnly/>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    }
                    {

                        orderStageCheck(b.orderTimeline,4)
                        &&
                        viewfeedback.data
                        &&
                        viewfeedback.data.dbData
                        &&
                        viewfeedback.data.dbData.length === 0
                        &&
                        <div style={{display:'flex',justifyContent:'center',margin:'30px 0px 50px'}}>
                            <div style={{margin:'0px 0px 10px',padding:'20px 50px 20px',borderRadius:'8px',width:'500px',
                                background:"linear-gradient(195deg, rgb(66, 66, 74), rgb(25, 25, 25))",
                                boxShadow:'rgba(0, 0, 0, 0.14) 0rem 0.25rem 1.25rem 0rem, rgba(64, 64, 64, 0.4) 0rem 0.4375rem 0.625rem -0.3125rem',
                                color:'#fff', textAlign:'center', fontSize:'large', letterSpacing:'1px', lineHeight:'28px'
                            }}>
                                <div style={{border:'0px dashed RED',background:'#4F4E4E',borderRadius:'5px'}}>How do you rate our services?</div>
                                <div style={{border:'0px dashed RED',textAlign:'left',padding:'20px 0px 0px',display:'flex'}}>
                                    <div style={{width:'70%'}}>Our Website's Experience</div>
                                    <div style={{width:'30%',textAlign:'right'}}>
                                        <Rating name="simple-controlled" value={rating[0]} onChange={(event, newvalue) => { updateRating(0,newvalue,rating) }} />
                                    </div>
                                </div>
                                <div style={{border:'0px dashed RED',textAlign:'left',padding:'20px 0px 20px',display:'flex'}}>
                                    <div style={{width:'70%'}}>Delivery Service</div>
                                    <div style={{width:'30%',textAlign:'right'}}>
                                        <Rating name="simple-controlled" value={rating[1]} onChange={(event, newvalue) => { updateRating(1,newvalue,rating) }} />
                                    </div>
                                </div>
                                <div style={{border:'0px dashed RED',background:'#4D4D4D',borderRadius:'5px'}}>How do you rate our products?</div>
                                {
                                    b.orderItems.map((items,index)=>(
                                        <div key={index} style={{border:'0px dashed RED',textAlign:'left',padding:'20px 0px 0px',display:'flex'}}>
                                            <div style={{width:'70%'}}>{items.productNameMarathi} / {items.productName}</div>
                                            <div style={{width:'30%',textAlign:'right'}}>
                                                <Rating name="simple-controlled" value={rating[index+2]} onChange={(event, newvalue) => { updateRating(index+2,newvalue,rating) }} />
                                            </div>
                                        </div>
                                    ))
                                }

                                <div onClick={submitFeedback} className="stdButton" style={{marginTop:'10px'}}>Submit</div>
                            </div>
                        </div>
                    }
                    <div style={{display:'flex',justifyContent:'center'}}>
                        <div style={cstyle1}>
                            <div style={{display:'flex',margin:'0px 0px 0px',flexDirection:'column',textAlign:'left'}}>
                                <div style={box1}>
                                    <FiTruck style={{display:'flex',margin:'auto 0px auto'}}/>
                                </div>
                                <div style={{height:'80px',position:'relative',top:'-40px',lineHeight:'25px'}}>
                                    <div style={{color:'#999'}}>Delivery Address</div>
                                    <div>{b.customerDetails_and_amount.name}</div>
                                    <div>{b.customerDetails_and_amount.address_line_1}</div>
                                    <div>{b.customerDetails_and_amount.address_line_2}</div>
                                    <div>{b.customerDetails_and_amount.area}, Dist: {b.customerDetails_and_amount.district}, {b.customerDetails_and_amount.state}</div>
                                    <div>{b.customerDetails_and_amount.pincode}</div>

                                </div>
                            </div>
                        </div>
                        <div style={cstyle1}>
                            <div style={{display:'flex',margin:'0 0px 0',flexDirection:'column',textAlign:'left'}}>
                                <div style={box2}>
                                    <FiUser style={{display:'flex',margin:'auto 0px auto'}}/>
                                </div>
                                <div style={{height:'80px',position:'relative',top:'-40px',lineHeight:'25px'}}>
                                    <div style={{color:'#999'}}>Contact Details</div>
                                    <div>{b.customerDetails_and_amount.name}</div>
                                    <div>Mobile: {b.customerDetails_and_amount.mobile}</div>
                                    <div>Email: {b.customerDetails_and_amount.email}</div>
                                </div>
                            </div>
                        </div>
                        <div style={cstyle1}>
                            <div style={{display:'flex',margin:'0 0px 0',flexDirection:'column',textAlign:'left'}}>
                                <div style={box3}>
                                    <FiHash style={{display:'flex',margin:'auto 0px auto'}}/>
                                </div>
                                <div style={{height:'80px',position:'relative',top:'-40px',lineHeight:'30px'}}>
                                    <div style={{color:'#999'}}>Other Details</div>
                                    <h2>₹{b.customerDetails_and_amount.grandtotal}.00</h2>
                                    <div>Payment Received</div>
                                </div>
                            </div>
                        </div>
                    </div>



                    <div style={{margin:'50px 0px 20px',padding:'10px',borderRadius:'8px'}}>
                        <h2>Order Tracking</h2>
                    </div>
                    <div style={{display:'flex',justifyContent:'center'}}><div style={{display:'flex',flexDirection:'column',lineHeight:'25px'}}>

                        {
                            orderStageCheck(b.orderTimeline,0) 
                            &&
                            orderTracking(true, "Order Canceled", "Your order has been canceled. Your amount will be refunded soon.")
                        }
                        {
                            !orderStageCheck(b.orderTimeline,0) && 
                            <div>
                                {
                                    orderStageCheck(b.orderTimeline,4) 
                                    ? 
                                    orderTracking(true, "Delivery Confirmed!", "Your order has been delivered")
                                    : 
                                    orderTracking(false, "Delivery Confirmation", "Awaiting")
                                }
                            </div>
                        }
                        {
                            !orderStageCheck(b.orderTimeline,0) && 
                            <div>
                                {
                                    orderStageCheck(b.orderTimeline,3) 
                                    ? 
                                    orderTracking(true, "Delivery Initiated", 
                                        "Your order is in transit at your shipping address: "
                                        +b.customerDetails_and_amount.area+", "
                                        +b.customerDetails_and_amount.state+", PIN: "+b.customerDetails_and_amount.pincode
                                    )
                                    : 
                                    orderTracking(false, "Delivery Initiation", "Awaiting")
                                }
                            </div>
                        }
                        {
                            !orderStageCheck(b.orderTimeline,0) && 
                            <div>
                                {
                                    orderStageCheck(b.orderTimeline,2) 
                                    ? 
                                    orderTracking(true, "We are working on your order", "Once your order is processed, we will initiate delivery")
                                    : 
                                    orderTracking(false, "Order Processing", "Awaiting")
                                }
                            </div>
                        }
                        {
                            orderStageCheck(b.orderTimeline,1) 
                            ? 
                            orderTracking(true, "Order Placed by Customer", "Order placed by customer. Order ID "+b.customerDetails_and_amount.orderId)
                            : 
                            orderTracking(false, "Order Purchased by Customer", "Awaiting")
                        }



                    </div></div>

                    <div style={{margin:'50px 0px 20px',padding:'10px',borderRadius:'8px'}}>
                        <h2>Your Bill</h2>
                    </div>
                    <div style={{display:'flex',justifyContent:'center'}}>
                        <div style={{display:'flex',flexDirection:'column',width:'800px',background:'#fff',padding:'20px 50px 20px'}}>

                            <div className="stdRow"> 

                                <div className="stdRowElements" style={{width:'calc(55% - 30px)',textAlign:'left',fontWeight:'bold',justifyContent:'flex-start'}}>
                                    <div style={{paddingLeft:'20px'}}>Product</div>
                                </div>
                                <div className="stdRowElements" style={{width:'calc(15% - 20px)'}}>Items</div>
                                <div className="stdRowElements" style={{width:'calc(15% - 20px)'}}>MRP ₹</div>
                                <div className="stdRowElements" style={{width:'calc(15% - 20px)'}}>Total ₹</div>

                            </div>
                            {
                                b.orderItems.map((data,index)=>(
                                    <div key={index} className="stdRow">
                                                            
                                        <div className="stdRowElements" style={{width:'calc(55% - 30px)',textAlign:'left',fontWeight:'bold',justifyContent:'flex-start'}}>
                                            
                                            <div style={{display:'flex',alignItems:'center',marginTop:'auto',marginBottom:'auto'}}>{data.productNameMarathi} / {data.productName}, Quantity: {data.qnt} </div>
                                        
                                        </div>

                                        <div className="stdRowElements" style={{width:'calc(15% - 20px)'}}>

                                            <div className="addCartText">{data.totalItems}</div>

                                        </div>

                                        <div className="stdRowElements" style={{width:'calc(15% - 20px)'}}>₹ {data.mrp}</div>
                                        <div className="stdRowElements" style={{width:'calc(15% - 20px)'}}>₹ {data.totalMrp && data.totalMrp.toFixed(2)}</div>

                                    </div>
                                ))
                            }
                            <div className="stdRow" style={{marginBottom:'0px'}}> 
                
                                <div className="stdRowElements" style={{width:'calc(30% - 40px)'}}></div>
                                <div className="stdRowElements" style={{width:'calc(0% - 50px)'}}></div>
                                <div className="stdRowElements" style={{width:'calc(60% - 0px)',justifyContent:'flex-end',fontWeight:'normal'}}>Order Total</div>
                                <div className="stdRowElements" style={{width:'calc(20% - 30px)',fontWeight:'bold'}}> ₹ {b.customerDetails_and_amount.totalAmount.toFixed(2)}</div>
                
                            </div>
                            <div className="stdRow" style={{margin:'15px 0px 0px'}}> 
                                <div className="stdRowElements" style={{width:'calc(30% - 40px)'}}></div>
                                <div className="stdRowElements" style={{width:'calc(0% - 50px)'}}></div>
                                <div className="stdRowElements" style={{width:'calc(60% - 0px)',justifyContent:'flex-end',fontWeight:'normal',textAlign:'right'}}>Delivery charge: {state.customerDetails.pincode}, {state.customerDetails.district} - {state.customerDetails.state}</div>
                                <div className="stdRowElements" style={{width:'calc(20% - 30px)',fontWeight:'bold'}}> ₹ {b.customerDetails_and_amount.pindeliverycharge.toFixed(2)}</div>
                            </div>
                            <div className="stdRow" style={{margin:'15px 0px 0px'}}> 
                                <div className="stdRowElements" style={{width:'calc(30% - 40px)'}}></div>
                                <div className="stdRowElements" style={{width:'calc(0% - 50px)'}}></div>
                                <div className="stdRowElements" style={{width:'calc(60% - 0px)',justifyContent:'flex-end',fontWeight:'bold'}}>Total Amount</div>
                                <div className="stdRowElements orangeBg" style={{width:'calc(20% - 30px)',fontWeight:'bold'}}> ₹ {b.customerDetails_and_amount.grandtotal.toFixed(2)}</div>
                            </div>
                        </div>
                    </div>


                    <div style={{margin:'50px 0px 10px',padding:'10px',borderRadius:'8px'}}>
                        <h2>Hope we are doing well</h2>
                    </div>
                    <div style={{display:'flex',justifyContent:'center'}}>
                        <div style={{margin:'0px 0px 10px',padding:'20px 50px 20px',borderRadius:'8px',width:'300px',
                            background:"linear-gradient(195deg, rgb(66, 66, 74), rgb(25, 25, 25))",
                            boxShadow:'rgba(0, 0, 0, 0.14) 0rem 0.25rem 1.25rem 0rem, rgba(64, 64, 64, 0.4) 0rem 0.4375rem 0.625rem -0.3125rem',
                            color:'#fff', textAlign:'center', fontSize:'large', letterSpacing:'1px', lineHeight:'28px'
                        }}>
                            If you have any queries on this order, please call us on +911111111111 and mention Order ID: <b>{b.customerDetails_and_amount.orderId}</b>
                        </div>
                    </div>
                    <div style={{display:'flex',justifyContent:'center',marginBottom:'100px'}}>
                    </div>

                </div>
            }


        </div>

    </div>
}

export default Orders