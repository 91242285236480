
import Menu from "./menu"

const Profile = () =>{

    return <div>
        <Menu/>

    </div>
}

export default Profile