import { TextField } from '@mui/material';

import { styled } from '@mui/system';


import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';

export const CustomTextField = styled(TextField)`
  & .MuiFilledInput-root {
    border-radius: 0;
    text-align:center;
    border: 1px solid ${props => (props.error ? '#F37512' : '#cccccc')};
    background-color: transparent;
    margin-top:20px;
    z-index: 1; 
  }

  & .MuiFilledInput-root.Mui-focused {
    background-color: transparent; /* Set transparent background when focused */
    z-index: 1; 
  }
  & .MuiFilledInput-root:hover {
    background-color: transparent; /* Set transparent background when focused */
    z-index: 1; 
  }

  & .MuiFormLabel-root {
    color: ${props => (props.error ? '#F37512' : '#888')}; /* Set label text color to black */
    margin-top:23px;
    z-index: 1; 
  }

  & .MuiFormLabel-root.Mui-focused {
    color: #F37512; /* Set label color when focused */
    z-index: 1; 
  }
  & .MuiFormHelperText-root.Mui-error {
    position: relative;
    z-index: 2; /* Adjust the z-index to bring the error text above the input */
  }
`;

export const CustomInput = styled(Input)`
  & .MuiFilledInput-root {
    border-radius: 0;
    text-align:center;
    border:0px solid #cccccc;
    background-color: transparent;
    margin-bottom:25px;
  }

  & .MuiFilledInput-root.Mui-focused {
    background-color: transparent; /* Set transparent background when focused */
  }
  & .MuiFilledInput-root:hover {
    background-color: transparent; /* Set transparent background when focused */
  }

  & .MuiFormLabel-root {
    color: #888888; /* Set label text color to black */
  }

  & .MuiFormLabel-root.Mui-focused {
    color: #F37512; /* Set label color when focused */
  }




`;

export const CustomSelect = styled(Select)`
  & .MuiFilledInput-root {
    border-radius: 0;
    text-align:center;
    border: 1px solid ${props => (props.error ? '#F37512' : '#cccccc')};
    background-color: transparent;
    margin-bottom:25px;
  }

  & .MuiFilledInput-root.Mui-focused {
    background-color: transparent; /* Set transparent background when focused */
  }
  & .MuiFilledInput-root:hover {
    background-color: transparent; /* Set transparent background when focused */
  }

  & .MuiFormLabel-root {
    color: #888888; /* Set label text color to black */
    margin-top:23px;
  }

  & .MuiFormLabel-root.Mui-focused {
    color: #F37512; /* Set label color when focused */
    background-color: transparent;
  }




`;
export const CustomFormControl = styled(FormControl)`
  & .MuiFilledInput-root {
    border-radius: 0;
    text-align:center;
    border:1px solid #cccccc;
    background-color: transparent;
    margin-top:25px;
  }

  & .MuiFilledInput-root.Mui-focused {
    background-color: transparent; /* Set transparent background when focused */
  }
  & .MuiFilledInput-root:hover {
    background-color: transparent; /* Set transparent background when focused */
  }

  & .MuiFormLabel-root {
    color: #888888; /* Set label text color to black */
    margin-top:23px;
  }

  & .MuiFormLabel-root.Mui-focused {
    color: #F37512; /* Set label color when focused */
  }



`;
export const CustomInputLabel = styled(InputLabel)`
  & .MuiFilledInput-root {
    border-radius: 0;
    text-align:center;
    border:1px solid #cccccc;
    background-color: transparent;
    margin-top:25px;
  }

  & .MuiFilledInput-root.Mui-focused {
    background-color: transparent; /* Set transparent background when focused */
  }
  & .MuiFilledInput-root:hover {
    background-color: transparent; /* Set transparent background when focused */
  }

  & .MuiFormLabel-root {
    color: #888888; /* Set label text color to black */
    margin-top:23px;
  }

  & .MuiFormLabel-root.Mui-focused {
    color: #F37512; /* Set label color when focused */
  }



`;