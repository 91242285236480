
// Defaults
import { useState } from "react"
import { useSelector,useDispatch } from "react-redux";

// Icons
import { BsEyeglasses,BsCardChecklist } from 'react-icons/bs';
import { MdShoppingCartCheckout } from 'react-icons/md';
import { TiDeleteOutline } from 'react-icons/ti'; 
import { CgShoppingCart } from 'react-icons/cg';
import { AiFillCloseCircle } from 'react-icons/ai';

// Components
import Cart from "./cart";

const ViewCart = () =>{

    // Primary Definitions
    const state=useSelector(state=>state)
    const dispatch = useDispatch();
    // Secondary Definitions
    const [viewCart,setViewCart] = useState(false);

    // Business Methods
    const showChart = (data) => {
        setViewCart(data)
    }
    const deleteCart = () =>{
        dispatch({type:'CART_DELETE_ALL'})
        dispatch({ type: 'SELECTED_DELALL' })
    }
    const checkout = () => {
        dispatch({type:'BACKDROP_ON'})
        dispatch({type:"CHANGE_BODY",payload:"UserDetails"})
    }

    return <div>

        {     
            viewCart
            &&
            <div className="cartView">

                <div className="titleBar">

                    <div className="titleBarName">

                        <CgShoppingCart style={{fontSize:'40px',paddingRight:'10px',display:'flex',alignItems:'center',margin:'auto 0px auto',fontWeight:'bold'}}/>

                        <h2 style={{display:'flex',alignItems:'center',margin:'auto 0px auto'}}><b>View Cart</b></h2>

                    </div>

                    <AiFillCloseCircle className="closeButton" onClick={()=>showChart(false)}/>

                </div>

                <div className="cartViewBody">

                    <Cart/>

                </div>

            </div>
        }
        {   
            state.cartCount!==0
            &&
            <div className="cartStatus">

                <div >
                    <BsCardChecklist style={{fontSize:'x-large',marginRight:'5px'}}/>Total Items: {state.cartCount}
                </div>

                {
                    viewCart
                    ?
                    <div className="cartRed" onClick={()=>showChart(false)} >
                        <BsEyeglasses style={{fontSize:'x-large',marginRight:'5px'}}/> Hide Cart
                    </div>
                    :
                    <div onClick={()=>showChart(true)} >
                        <BsEyeglasses style={{fontSize:'x-large',marginRight:'5px'}}/> View Cart
                    </div>
                }

                <div onClick={deleteCart} >
                    <TiDeleteOutline style={{fontSize:'x-large',marginRight:'5px'}}/> Empty Cart
                </div>

                {
                    state.body === "home"
                    &&
                    <div onClick={checkout} >
                        <MdShoppingCartCheckout style={{fontSize:'x-large',marginRight:'5px'}}/> Proceed for Checkout
                    </div>
                }

            </div>

        }

            
    </div>
}

export default ViewCart