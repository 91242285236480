


export const MobileValidation = { value:/^\d{10}$/, message:"Enter valid 10 digits mobile number" };


export const ValidateSQLInjection = (value) => {
    /*
        Clear out the special characters mentioned in regex - sqliRegex
        Allows: [space] ['] [,]
    */
    const sqliRegex = /['";<>%()|`~!$^&*_=+/\\]+/;
    if (sqliRegex.test(value)) {
      return 'Field contains invalid characters';
    }
    return true;
  };


export const Pincode = { value:/^\d{6}$/, message:"Enter valid 6 digits Indian pin code" };

export const EmailValidation = {value:/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, message:"Enter valid email address"};