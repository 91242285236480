
import { useForm } from 'react-hook-form';

// MUI
import { CustomTextField } from "../mui/text";
import { FormHelperText } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import md5 from 'md5';
import fetchData from "../api/api";

const RegisterUser = () =>{

    // Primary Components
    const dispatch = useDispatch();
    const { register, handleSubmit, formState: { errors }, trigger,watch } = useForm();
    const password1 = watch('password1');
    const [content,setcontent] = useState([]);
    const [apiresponse,setapiresponse] = useState([]);

    const state = useSelector((state)=>state);

    // Business Methods
    const handleChange = async (event) => {
        await trigger(event.target.name);
    };
    const onSubmit = (data) => {
        setcontent({
            type:state.customerDetails.type,
            name:state.customerDetails.name,
            mobile:state.customerDetails.mobile,
            email:state.customerDetails.email,
            pincode:state.customerDetails.pincode,
            area:state.customerDetails.area,
            dist:state.customerDetails.district,
            state:state.customerDetails.state,
            addressline1:state.customerDetails.address_line_1,
            addressline2:state.customerDetails.address_line_2,
            password:md5(data.password1)
        })

        const secret = md5(data.password1);
        
        dispatch({type:'BACKDROP_ON'})
        const getData = async () => {
            var uri = 'api/v1.0/public/registration';
            var body = {
                "type":state.customerDetails.type,
                "name":state.customerDetails.name,
                "mobile":state.customerDetails.mobile,
                "email":state.customerDetails.email,
                "pincode":state.customerDetails.pincode,
                "area":state.customerDetails.area,
                "dist":state.customerDetails.district,
                "state":state.customerDetails.state,
                "addressline1":state.customerDetails.address_line_1,
                "addressline2":state.customerDetails.address_line_2,
                "secret":secret
            };
            const data = await fetchData(uri,body);
            try{
                if (data) {
                    setapiresponse(data)
                }
                dispatch({type:'BACKDROP_OFF'})
            }
            catch (error) {
                setapiresponse(error)
                dispatch({type:'CHANGE_BODY',payload:'Maintenance'})
            }
        };
        getData();

    };

    return <div>
        
        <div style={{display:'flex',justifyContent:'center'}}>
                <div className="stdPadding stdMargin" style={{background:'#fff',width:'450px'}}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <h2 >Confirm Authentication</h2>

                        <div className="stdMargin" style={{margin:'10px 10px 0px'}}>
                            {/*
                                --------------- PASSWORD 1 ---------------
                            */}
                            <CustomTextField 
                                name="password1" 
                                {...register(
                                    'password1', 
                                    { 
                                        required: true, 
                                        minLength: { 
                                            value: 8, 
                                            message: 'Password should have at least 8 characters' 
                                        } 
                                    }
                                    )
                                } 
                                type="password"
                                InputProps={{
                                    disableUnderline:true
                                }}
                                onKeyUp={handleChange} error={errors.password1} 
                                style={{width:'100%'}} label="Enter Password" variant="filled"
                            />
                            {errors.password1 && <FormHelperText error style={{color:'#F37512'}}>{errors.password1.message}</FormHelperText>}
                        </div>

                        <div className="stdMargin" style={{margin:'0px 10px 0px'}}>
                            {/*
                                --------------- PASSWORD 2 ---------------
                            */}
                            <CustomTextField 
                                name="password2" 
                                {...register(
                                    'password2', 
                                    {
                                        required: 'This field is mandatory',
                                        minLength: { value: 8, message: 'Password should have at least 8 characters' },
                                        validate: (value) => value === password1 || 'Passwords do not match'
                                    }
                                    )
                                } 
                                type="password"
                                InputProps={{
                                    disableUnderline:true
                                }}
                                onKeyUp={handleChange} error={errors.password2} 
                                style={{width:'100%'}} label="Confirm Password" variant="filled"
                            />
                            {errors.password2 && <FormHelperText error style={{color:'#F37512'}}>{errors.password2.message}</FormHelperText>}

                            {
                                Object.keys(errors).length === 0
                                ?
                                <div type='submit' style={{marginTop:'23px'}} onClick={handleSubmit(onSubmit)} className="stdButton">Proceed</div>
                                :
                                <div>
                                    <div disabled type='submit' style={{marginTop:'23px'}} className="stdButtonDisabled">Proceed</div>
                                    {/*}<div style={{color:'#888',paddingTop:'10px'}}>Ensure all fields are correct above</div>{*/}
                                </div>
                            }

                        </div>

                    </form>
                    <pre style={{textAlign:'left'}}>{JSON.stringify(content,2,2)}</pre>
                    <pre style={{textAlign:'left'}}>{JSON.stringify(apiresponse,2,2)}</pre>
                </div>
        </div>
    </div>
}

export default RegisterUser