/*
        ----- Important Notice -----
        This code is the intellectual property of Laxab Digital Solutions Pvt Ltd (Regd company in India,
        hereafter referred as 'company'). If you are not an employee this company, you are not authorized 
        to copy, save, or transmit this code in any form or medium without prior written permission from 
        the company.

        For more information, please visit company's website: www.laxab.com/intellectualproperty

        ----->> Footer <<-----

        Version 1.0 : 09 Jun 2023, Created      ***Current version***
*/
import { useEffect, useState } from "react";
import fetchData from "../../businessComponents/api/api";

const Footer = () => {
    const [c,setc] = useState();
    useEffect(()=>{

        const updateData = async () =>{
            const uri='api/be/v1.0/inventory/getBusinessInfo';
            const body={}

            const data = await fetchData(uri,body)
            try{
                setc(data)
            }
            catch (error){
            }
        }
        updateData()

    },[])

    return <div className="footerBase">

        <div style={{display:'flex',justifyContent:'center',width:'100%'}}>

            <div className="footerColumn">
                <div className="footerContents" style={{color:'#999999'}}><b>{c && c.data && c.data.dbData && c.data.dbData.length!==0 && c.data.dbData[0].businessName && c.data.dbData[0].businessName.toUpperCase()}</b></div>
                <div className="footerContents">{c && c.data && c.data.dbData && c.data.dbData.length!==0 && c.data.dbData[0].businessAddress && c.data.dbData[0].businessAddress}</div>
                <div className="footerContents">Email us at: {c && c.data && c.data.dbData && c.data.dbData.length!==0 && c.data.dbData[0].businessEmail && c.data.dbData[0].businessEmail}</div>
                <div className="footerContents">Contact: +91 {c && c.data && c.data.dbData && c.data.dbData.length!==0 && c.data.dbData[0].contact && c.data.dbData[0].contact}</div>
            </div>
            <div className="footerColumn">
                <div className="footerContents" style={{color:'#999999'}}><b>TERMS OF USE</b></div>
                <div className="footerContents" style={{display:'flex'}}>By accessing the website, you agree to these Terms & Conditions. If you have any queries / concerns, please do email us at {c && c.data && c.data.dbData && c.data.dbData.length!==0 && c.data.dbData[0].relationsEmail && c.data.dbData[0].relationsEmail}</div>
            </div>
            <div className="footerColumn">
                <div className="footerContents" style={{color:'#999999'}}><b>LEGAL</b></div>
                <div className="footerContents" style={{display:'flex'}}>By accessing the website, you agree to these Terms & Conditions. If you have any queries / concerns, please do email us at {c && c.data && c.data.dbData && c.data.dbData.length!==0 && c.data.dbData[0].relationsEmail && c.data.dbData[0].relationsEmail}</div>
            </div>
            <div className="footerColumn" style={{background:'#000',padding:'0px',width:'10%'}}>
                <div className="footerContents" style={{color:'#999999',textAlign:'center'}}><b>POWERED BY</b></div>
                <div className="celogo">ce</div>
                <div className="footerContents" style={{textAlign:'center',fontFamily:'logo',fontSize:'25px',padding:'0px',margin:'0px',color:'#aaa'}}>Customer Exp.</div>
                <div className="footerContents" style={{textAlign:'center',color:'#aaa',padding:'0px',margin:'0px',fontSize:'12px'}}>v1.2</div>
                <div className="footerContents" style={{textAlign:'center',padding:'0px'}}><a style={{color:'#aaa',textDecoration:'none'}} href="https://www.laxab.com/ce">www.laxab.com/ce</a></div>
            </div>
            
        </div>

        <div className="footerRow" style={{ flexWrap: 'wrap', flexDirection: 'column' }}>
            &copy; 2023 {c && c.data && c.data.dbData && c.data.dbData.length!==0 && c.data.dbData[0].businessName && c.data.dbData[0].businessName}. All rights reserved.
        </div>

</div>
}

export default Footer