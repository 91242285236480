
import { useDispatch, useSelector } from "react-redux"
import Banner from "../body/banner"
import Cart from "../cart/cart"
import { useState } from "react";
import fetchData from "../api/api";

const Checkout = () =>{

    // Primary Definitions
    const state = useSelector((state)=>state);
    const dispatch = useDispatch();

    // Secondary Definitions
    const [a,seta] = useState([])
    const redirect=()=>{
        dispatch({type:'BACKDROP_ON'})
        dispatch({type:"CHANGE_BODY",payload:"UserDetails"})
    }
    const decimals = (a) =>{
        var c = parseInt(a)
        return c.toFixed(2)
    }
    const confirmBooking = () =>{

        dispatch({type:'BACKDROP_ON'})
        const apiCall1 = async () => {
            var uri = 'api/v1.0/public/confirmorder';
            var body = {
                "cartItems": state.cartItems,
                "customerDetails":state.customerDetails,
                "totalAmount":state.totalAmount,
                "pindeliverycharge":state.pindeliverycharge,
                "grandtotal":state.grandtotal
            };
            const data = await fetchData(uri,body);
            try{
                if (data) {
                    seta(data)
                }
                dispatch({type:'BACKDROP_OFF'})
            }
            catch (error) {
                dispatch({type:'BACKDROP_OFF'})
                dispatch({type:'CHANGE_BODY',payload:'Maintenance'})
            }
        }
        apiCall1();
    }

    return <div>
        <Banner type="billing"/>
        <div style={{display:'flex',justifyContent:'center',flexWrap:'wrap'}}>
            <div className="stdPadding stdMargin" style={{background:'#fff'}}>

                <h2 style={{marginTop:'0px'}}>Order Details</h2>

                <div className="stdRow stdPadding_2" style={{width:'730px',margin:'20px auto 0px',flexDirection:'column',textAlign:'left'}} >
                    <div><b>Delivery to: {state.customerDetails.name}</b></div>
                    <div>{state.customerDetails.address_line_1}</div>
                    <div>{state.customerDetails.address_line_2}</div>
                    <div>{state.customerDetails.area}, PIN: {state.customerDetails.pincode}</div>
                    <div>District: {state.customerDetails.district}, {state.customerDetails.state}</div>

                    <div className="stdPadding_2" style={{paddingLeft:'0px',paddingRight:'0px'}}>Mobile Number: {state.customerDetails.mobile}</div>
                    <div>Email Address: {state.customerDetails.email}</div>
                    <div className="stdButton" onClick={()=>redirect()} style={{width:'200px',margin:'20px 0px 0px',textAlign:'center'}}>Change Details</div>
                </div>


                <h2 style={{marginTop:'48px'}}>Your Order</h2>
                <Cart/>

                <div className="stdRow" style={{margin:'15px 15px 0px'}}> 
                    <div className="stdRowElements" style={{width:'calc(30% - 40px)'}}></div>
                    <div className="stdRowElements" style={{width:'calc(0% - 50px)'}}></div>
                    <div className="stdRowElements" style={{width:'calc(60% - 0px)',justifyContent:'flex-end',fontWeight:'normal',textAlign:'right'}}>Delivery charge: {state.customerDetails.pincode}, {state.customerDetails.district} - {state.customerDetails.state}</div>
                    <div className="stdRowElements" style={{width:'calc(20% - 30px)',fontWeight:'bold'}}> ₹ {decimals(state.pindeliverycharge)}</div>
                </div>
                <div className="stdRow" style={{margin:'15px 15px 0px'}}> 
                    <div className="stdRowElements" style={{width:'calc(30% - 40px)'}}></div>
                    <div className="stdRowElements" style={{width:'calc(0% - 50px)'}}></div>
                    <div className="stdRowElements" style={{width:'calc(60% - 0px)',justifyContent:'flex-end',fontWeight:'bold'}}>Total Amount</div>
                    <div className="stdRowElements orangeBg" style={{width:'calc(20% - 30px)',fontWeight:'bold'}}> ₹ {state.grandtotal}</div>
                </div>

                <h2 style={{marginTop:'48px'}}>Payment Method</h2>

                <div className="stdRow stdPadding_2" style={{width:'730px',margin:'20px auto 0px',flexDirection:'column',textAlign:'left'}} >
                    <div style={{margin:'0px auto 0px'}}>
                        <img src='https://upload.wikimedia.org/wikipedia/commons/8/89/Razorpay_logo.svg' alt="Activation Pending" style={{height:'50px'}}/>
                    </div>
                    <div style={{color:'#aaaaaa',margin:'0px auto 0px'}}>
                        Activation Pending
                    </div>
                    <div className="stdButton" onClick={()=>confirmBooking()} style={{width:'200px',margin:'20px auto 0px',textAlign:'center'}}>Pay ₹ {state.grandtotal}</div>
                </div>

                <pre style={{textAlign:'left'}}>
                    API_RESPONSE:{JSON.stringify(a,2,2)}
                </pre>

            </div>
        </div>

    </div>
}

export default Checkout