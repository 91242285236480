/*
        ----- Important Notice -----
        This code is the intellectual property of Laxab Digital Solutions Pvt Ltd (Regd company in India,
        hereafter referred as 'company'). If you are not an employee this company, you are not authorized 
        to copy, save, or transmit this code in any form or medium without prior written permission from 
        the company.

        For more information, please visit company's website: www.laxab.com/intellectualproperty

        ----->> app.js  >  root.js

        Version 1.0 : 09 Jun 2023, Created      ***Current version***
*/

// Default Imports
import '../../styles/stylesheets/main.scss';

// Components
import Header from './header';
import Body from './body';
import Footer from './footer';
import { useSelector } from 'react-redux';

const Root = () =>{

    const state = useSelector(state => state)

    return <>

        <Header/>

        <Body/>
        
        {
            state.body!=="Orders"
            &&
            <Footer/>
        }

    </>
}

export default Root