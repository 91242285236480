/*
        ----- Important Notice -----
        This code is the intellectual property of Laxab Digital Solutions Pvt Ltd (Regd company in India,
        hereafter referred as 'company'). If you are not an employee this company, you are not authorized 
        to copy, save, or transmit this code in any form or medium without prior written permission from 
        the company.

        For more information, please visit company's website: www.laxab.com/intellectualproperty

        ----->> Maintenance Page <<-----

        Version 1.0 : 09 Jun 2023, Created      ***Current version***
*/

// Icons
import { GiAutoRepair } from 'react-icons/gi';

const Maintenance = () => {

    return <div style={{display:'flex',justifyContent:'center'}}>
        
        <div className="stdMargin stdPadding" style={{background:'white',width:'60%',textAlign:'center'}}>

            <div style={{display:'flex',justifyContent:'center'}}>
                <GiAutoRepair style={{marginRight:'10px', display:'flex',alignItems:'center',margin:'auto 0px auto',fontSize:'40px',paddingRight:'10px'}}/> 
                <h2 style={{justifyContent:'center', display:'flex',alignItems:'center',margin:'auto 0px auto'}}>
                    Site Under Maintenance
                </h2>
            </div>

            <div style={{display:'flex',justifyContent:'center',padding:'30px 30px 0px'}}>
                We apologize for the inconvenience, but our site is currently undergoing maintenance.
                Please check back shortly, as we are working hard to get everything up and running smoothly again.
            </div>

        </div>
        
    </div>
}

export default Maintenance