

import { useState } from "react";
import { CustomTextField } from "../mui/text";
import { useForm } from 'react-hook-form';
import fetchData from "../api/api";
import { useSelector,useDispatch } from "react-redux";
import { HiBadgeCheck } from 'react-icons/hi';

const Search = () =>{

    const { register, handleSubmit, formState: { errors }, trigger,watch } = useForm();
    const state=useSelector(state=>state)
    const dispatch = useDispatch();
    const password1 = watch('password1');
    const [products,setProducts] = useState([]);

    const handleChange = async (event) => {

        const getdetails = async () =>{
            const uri='api/v1.0/public/searchInventory';
            const body={"key":event.target.value}
            const data = await fetchData(uri,body)
            try{
                if(data.status==="success")
                    if(data.data.dbData.length!==0)
                        setProducts(data.data.dbData)
                    else
                        setProducts()
                else  setProducts()

            }
            catch (error){

            }
        }

        if(event.target.value.length>2)
            getdetails()

        await trigger(event.target.name);
    };
    const onSubmit = (data) => {}
    // Business Methods
    const addToCart = (data) => {
        const totalCost = data.totalItems * data.mrp;
        const updatedProducts = products.map((item) =>
            item.pid === data.pid && item.did === data.did
            ? { ...item, selected: true, totalMrp: totalCost }
            : item
        );
        setProducts(updatedProducts);
        dispatch({ type: 'CART_ADD', payload: data, count: data.length });
        dispatch({ type: 'SELECTED_ADD', payload: data.id })
    };
    const deleteFromCart = (data) => {
        var counter = state.cartItems.length - 1;
        dispatch({type:'CART_DELETE',payload:data,count:counter})
        setProducts((prevData)=>prevData.map(item => (item.pid === data.pid && item.did === data.did) ? {...item, selected:false} : item ))
        dispatch({ type: 'SELECTED_DEL', payload: data.id })

    }

    return <div style={{minHeight:'300px',padding:'20px',border:'0px dashed RED'}}>
        <form onSubmit={handleSubmit(onSubmit)} style={{width:'100%'}}>
            <CustomTextField 
                name="search" 
                {...register(
                    'search', 
                    {
                        required: 'This field is mandatory',
                        minLength: { value: 8, message: 'Password should have at least 8 characters' },
                        validate: (value) => value === password1 || 'Passwords do not match'
                    }
                    )
                } 
                InputProps={{
                    disableUnderline:true,
                }}
                onKeyUp={handleChange} error={errors.search} 
                style={{width:'60%'}} label="Search" variant="filled"
            />
        </form>


        <div style={{display:'flex',flexWrap:'wrap',justifyContent: 'center',alignItems: 'center'}}>
                
                {
                    products &&
                    products.length!==0 &&
                    products.map((product,index)=>{

                        const isSelected = state.selected.includes(product.id)

                            if(1)
                            {
                                return  <div key={index} >
                                {
                                    isSelected
                                    ?
                                    (
                                        <div className="banner_custom effect5" style={{background:'#ffffff'}} >
                                            
                                            <div className="banner_custom_left">
                                                {
                                                    product.image ?
                                                    <img alt="" src={"https://api.98b870ed6919e4ffdd74527f19020ae9.laxab.com/api/upload_productImages/"+product.image} style={{width:'150px',height:'258px'}}/>
                                                    :
                                                    <img alt="" src="https://api.98b870ed6919e4ffdd74527f19020ae9.laxab.com/api/upload_productImages/image-1689537481715-598110061.png" style={{width:'150px',height:'258px'}}/>
                                                }
                                            </div>

                                            <div >
        
                                                <div className="banner_custom_right" style={{position:'relative'}}>
            
                                                    <div style={{margin:'10px 10px 0px',border:'0px dashed red',paddingBottom:'0',fontSize:'x-large',textAlign:'left'}}>{product.productNameMarathi}</div>
            
                                                    <div style={{margin:'0px 10px 0px',border:'px dashed red',paddingBotton:'0',paddingTop:'0',fontSize:'normal',textAlign:'right',letterSpacing:'1px'}}>
                                                        <b>{product.productName}</b>
                                                    </div>
            
            
                                                    <div style={{border:'0px solid #ddd',width:'100%',height:'145px',display:'flex',justifyContent:'center'}}>
                                                        <div style={{display:'flex',margin:'auto 0px',fontSize:'small',letterSpacing:'1px',flexDirection:'column'}}>
                                                            <div style={{color:'#60b246',fontSize:'75px'}}><HiBadgeCheck/></div>
                                                            <div>ADDED TO CART</div>
                                                            <div>Cost: {product.mrp.toFixed(2)} | Qnt: {product.qnt}{product.qntType}</div>
                                                        </div>
                                                    </div>
            
                                                    <div onClick={()=>deleteFromCart(product)} className="bannerButton orangeBg" style={{textAlign:'center',position:'absolute',bottom:'0px',left:'0px'}}>
                                                        <b>Remove</b>
                                                    </div>
                                                </div>
                                                
                                            </div>
        
                                        </div>

                                    )
                                    :
                                    (
                                        <div className="banner_custom effect2" style={{background:'#ffffff'}} >
                                          
                                            <div className="banner_custom_left">
                                                {
                                                    product.image ?
                                                    <img alt="" src={"https://api.98b870ed6919e4ffdd74527f19020ae9.laxab.com/api/upload_productImages/"+product.image} style={{width:'150px',height:'258px'}}/>
                                                    :
                                                    <img alt="" src="https://api.98b870ed6919e4ffdd74527f19020ae9.laxab.com/api/upload_productImages/image-1689537481715-598110061.png" style={{width:'150px',height:'258px'}}/>
                                                }
                                                
                                            </div>

                                            <div >
        
                                                <div className="banner_custom_right" style={{position:'relative'}}>
            
                                                    <div style={{margin:'10px 10px 0px',border:'0px dashed red',paddingBottom:'0',fontSize:'x-large',textAlign:'left'}}>{product.productNameMarathi}</div>
            
                                                    <div style={{margin:'0px 10px 0px',border:'px dashed red',paddingBotton:'0',paddingTop:'0',fontSize:'normal',textAlign:'right',letterSpacing:'1px'}}>
                                                        <b>{product.productName}</b>
                                                    </div>
            
                                                    <div style={{border:'0px dashed red',display:'flex',alignItems:'center',justifyContent:'center',padding:'10px 0px',fontSize:'small',letterSpacing:'2px'}}>
                                                        <div className="bannerLabels">QUANTITY: {product.qnt}{product.qntType}</div>
                                                    </div>
                                            
                                                    {
                                                        product.nonDiscountMrp-product.mrp > 0 &&
                                                        <div>
                                                            <div style={{width:'100%',display:'flex',justifyContent:'center',border:'0px dashed green',lineHeight:'10px'}}>
                                                                <div style={{display:'flex',color:'#999',padding:'5px',margin:'auto 0px',fontSize:'small',letterSpacing:'3px'}}>
                                                                    DISCOUNT
                                                                </div>
                                                            </div>
                                                            <div style={{width:'100%',display:'flex',justifyContent:'center',border:'0px dashed green',lineHeight:'6px'}}>
                                                                <div style={{display:'flex',color:'#999',fontSize:'small',padding:'5px',margin:'auto 0px',textDecoration: 'line-through',letterSpacing:'1px'}}>
                                                                    ₹{product.nonDiscountMrp.toFixed(2)}
                                                                </div>
                                                                <div style={{display:'flex',padding:'7px 0px 7px',color:'#F37512',fontSize:'small'}}>
                                                                    <b>₹{((product.nonDiscountMrp-product.mrp)).toFixed(2)} OFF</b>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    }


                                                    <div style={{display:'flex',justifyContent:'center',border:'0px dashed #dddddd',width:'100%',position:'absolute',bottom:'60px',left:'0px',height:'50px'}}>
                                                        <div style={{display:'flex',margin:'auto 0px',borderRadius:'20px',border:'1px dashed #ccc',padding:'5px 10px 5px',color:'#60b246'}}>
                                                            <div style={{marginLeft:'0px',fontSize:'14px',paddingTop:'2px'}}>₹</div>
                                                            <div style={{marginLeft:'0px'}}><b style={{fontSize:'25px'}}>{product.mrp.toFixed(2)}</b></div>
                                                        </div>
                                                    </div>
            
                                                    <div onClick={()=>addToCart(product)} className="bannerButton" style={{textAlign:'center',position:'absolute',bottom:'0px',left:'0px'}}>
                                                        <b>Add to Cart </b>
                                                    </div>

                                                </div>
                                            </div>
        
                                        </div>

                                    )
                                }

                            </div>
                        }
                        return null
                    })
                }

            </div>


    </div>
}

export default Search