
// Defaults
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useForm } from 'react-hook-form';

// COMPONENTS
import Banner from "../body/banner";
import fetchData from "../api/api";

// MUI
import { CustomTextField, CustomSelect, CustomInputLabel, CustomFormControl } from "../mui/text";
import { FormHelperText } from '@mui/material';
import { MenuItem } from '@mui/material';

// Components
import ViewCart from "../cart/viewCart"


import { MobileValidation, ValidateSQLInjection, Pincode, EmailValidation } from "../mui/validations"

const UserDetails = () =>{


    // PRIMARY DEFINITIONS
    const st = useSelector((state)=>state)
    const dispatch = useDispatch();
    const { register, handleSubmit,setValue, formState: { errors }, trigger, watch } = useForm();
    const dist_form = watch('district'); 
    const state_form = watch('state'); 

    useEffect(()=>{

        dispatch({type:'BACKDROP_OFF'})



        dispatch({type:'BACKDROP_ON'})
        const getpincost = async (pin) => {
            const uri = 'api/be/v1.0/inventory/pincheck'
            const body = {"pincode":pin}
            const data = await fetchData(uri,body)
            try{
                dispatch({type:'PIN_DELIVERY_CHARGE',payload:parseInt(data.data.cost)})
                dispatch({type:'BACKDROP_OFF'})
            }
            catch(error){
                dispatch({type:'PIN_DELIVERY_CHARGE',payload:parseInt(9999)})
                dispatch({type:'BACKDROP_OFF'})
            }
        }
        

        dispatch({type:'BACKDROP_ON'})
        const getDataRedux = async () => {
            var uri = 'api/v1.0/public/pinsearch';
            var body = {"pin": st.customerDetails.pincode};
            const data = await fetchData(uri,body);
            try{
                if (data) {
                    if(data.data.dbData.length >= 1){
                        setState(data.data.dbData[0].state)
                        setDist(data.data.dbData[0].district)
                        getpincost(st.customerDetails.pincode)
                        //dispatch({type:'PIN_DELIVERY_CHARGE',payload:parseInt(data.data.dbData[0].flag)})
                        setarea(data.data.dbData)
                        setValue('state', data.data.dbData[0].state);
                        setValue('district', data.data.dbData[0].district);
                    }
                    else{
                        setState("Incorrect Pincode")
                        setDist("Incorrect Pincode")
                        setValue('district', "Incorrect Pincode");
                        setValue('state', "Incorrect Pincode");
                    }
                }
                dispatch({type:'BACKDROP_OFF'})
            }
            catch (error) {
                dispatch({type:'BACKDROP_ON'})
                dispatch({type:'CHANGE_BODY',payload:'Maintenance'})
            }
        }

        if(st.customerDetails.pincode){
            getDataRedux() 
        }
    },[dispatch,st.customerDetails.pincode,setValue,st.totalAmount])

    // SECONDARY DEFINITIONS
    const [dist,setDist] = useState("");
    const [state,setState] = useState("");
    const [area,setarea] = useState([]);
    const [selectedOption, setSelectedOption] = useState('Select Area based on your pincode');
    const [authtype,setauthtype] = useState("password");

    // Business Methods
    const handleChange = async (event) => {
        await trigger(event.target.name);
    };
    const selectArea = (event) => {
      setSelectedOption(event.target.value);
      dispatch({type:'CUSTOMER_DETAILS_AREA',payload:event.target.value})
    };
    const checkPin = async (event) => {



        dispatch({type:'BACKDROP_ON'})
        const getpincost = async (pin) => {
            const uri = 'api/be/v1.0/inventory/pincheck'
            const body = {"pincode":pin}
            const data = await fetchData(uri,body)
            try{
                dispatch({type:'PIN_DELIVERY_CHARGE',payload:parseInt(data.data.cost)})
                dispatch({type:'BACKDROP_OFF'})
            }
            catch(error){dispatch({type:'BACKDROP_OFF'})}
        }
        

        dispatch({type:'BACKDROP_ON'})
        const getData = async () => {
            var uri = 'api/v1.0/public/pinsearch';
            var body = {"pin": event.target.value};
            const data = await fetchData(uri,body);
            try{
                if (data) {
                    if(data.data.dbData.length >= 1){
                        setState(data.data.dbData[0].state)
                        setDist(data.data.dbData[0].district)
                        getpincost(event.target.value)
                        //dispatch({type:'PIN_DELIVERY_CHARGE',payload:parseInt(data.data.dbData[0].flag)})
                        setarea(data.data.dbData)
                        setValue('state', data.data.dbData[0].state);
                        setValue('district', data.data.dbData[0].district);
                    }
                    else{
                        setState("Incorrect Pincode")
                        setDist("Incorrect Pincode")
                        setValue('district', "Incorrect Pincode");
                        setValue('state', "Incorrect Pincode");
                    }
                }
                dispatch({type:'BACKDROP_OFF'})
            }
            catch (error) {
                dispatch({type:'BACKDROP_ON'})
                dispatch({type:'CHANGE_BODY',payload:'Maintenance'})
            }
        };
        getData();

    };
    const onSubmit = (data) => {
        if(st.cartCount === 0)
            setauthtype("password");
        else
            setauthtype("password");

        const contents = {
            type:authtype,
            name:data.name,
            mobile:data.mobile,
            email:data.email,
            pincode:data.pincode,
            area:data.area,
            dist:data.dist,
            state:data.state,
            addressline1:data.addressline1,
            addressline2:data.addressline2
        }
        console.log(contents);

        if (data.area === 'Select Area based on your pincode'){
            alert("Please select correct area")
        }
        else{
            dispatch({type:'CUSTOMER_DETAILS',payload:data,usertype:authtype})
            console.log(data);
            if(st.cartCount === 0){
                dispatch({type:'BACKDROP_ON'})
                dispatch({type:"CHANGE_BODY",payload:"RegisterUser"})
            }
            else{
                dispatch({type:'BACKDROP_ON'})
                dispatch({type:"CHANGE_BODY",payload:"Checkout"})
            }
        }
    };
    const handlelogin =() =>{
        dispatch({type:'BACKDROP_ON'})
        dispatch({type:"CHANGE_BODY",payload:"Login"})

    }
    return <div>

        <Banner type="checkout"/>
        <div className={'ThemeDefault'}>

            <div style={{display:'flex',justifyContent:'center',flexWrap:'wrap'}}>

                <div className="backbone" style={{flexWrap:'wrap',minHeight:'50vh',flexDirection:'column',justifyContent:'center',alignContent:'center'}}>


                {
                    st.loginStatus===true
                    ?
                    <div className="stdMargin" style={{marginTop:'0px'}}></div>
                    :
                    <div className="stdMargin stdPadding" style={{background:'white',width:'400px',textAlign:'center'}}>
                        <div>Kindly login if you have already registered, or else continue with the below form</div>
                        <div style={{marginTop:'10px'}} onClick={()=>handlelogin()} className="stdButton">Login</div>
                        <div style={{marginTop:'10px'}}>आधीच नोंदणी केली असल्यास कृपया वरिल Login बटण क्लिक करा, अन्यथा खालील फॉर्म भरा</div>
                    </div>
                }


                    <div className="stdMargin stdPadding" style={{background:'white',width:'400px',textAlign:'center',marginTop:'0px'}}>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <h2>Contact Details</h2>

                            <div className="stdMargin" style={{margin:'0px 30px 0px'}}>
                                    {/*
                                        --------------- NAME ---------------
                                    */}
                                    <CustomTextField 
                                        name="name" 
                                        {...register(
                                            'name', 
                                            {
                                                required:"This field is mandatory",
                                                validate: ValidateSQLInjection
                                            }   
                                            )
                                        } 
                                        onKeyUp={handleChange} 
                                        error={errors.name} 
                                        style={{width:'100%'}} label="Name" variant="filled" InputProps={{disableUnderline:true}} defaultValue={st.customerDetails.name && st.customerDetails.name}
                                    />
                                    {errors.name && <FormHelperText error style={{color:'#F37512'}}>{errors.name.message}</FormHelperText>}

                                    {/*
                                        --------------- MOBILE ---------------
                                    */}
                                    <CustomTextField 
                                        name="mobile" 
                                        {...register(
                                            'mobile', 
                                            {
                                                required:"This field is mandatory",
                                                pattern: MobileValidation,
                                                validate: ValidateSQLInjection
                                            }
                                            )
                                        } 
                                        onKeyUp={handleChange} error={errors.mobile} 
                                        style={{width:'100%'}} label="Mobile Number" variant="filled" InputProps={{disableUnderline:true}} defaultValue={st.customerDetails.mobile && st.customerDetails.mobile}
                                    />
                                    {errors.mobile && <FormHelperText error style={{color:'#F37512'}}>{errors.mobile.message}</FormHelperText>}
                                    
                                    {/*
                                        --------------- EMAIL ---------------
                                    */}
                                    <CustomTextField 
                                        name="email" 
                                        {...register(
                                            'email', 
                                            { 
                                                required:"This field is mandatory",
                                                pattern: EmailValidation,
                                                validate: ValidateSQLInjection
                                            }
                                            )
                                        } 
                                        onKeyUp={handleChange} error={errors.email && "This is mandetory"}  defaultValue={st.customerDetails.email && st.customerDetails.email}
                                        style={{width:'100%'}} label="Email Address" variant="filled" InputProps={{disableUnderline:true}} 
                                    />
                                    {errors.email && <FormHelperText error style={{color:'#F37512'}}>{errors.email.message}</FormHelperText>}

                            </div>

                            <h2 style={{marginTop:'28px'}}>Shipping Address</h2>

                            <div className="stdMargin" style={{margin:'0px 30px 0px'}}>


                                    {/*
                                        --------------- PINCODE ---------------
                                    */}
                                    <CustomTextField 
                                        name="pincode" 
                                        {...register(
                                            'pincode', 
                                            {
                                                required:"This field is mandatory",
                                                pattern:Pincode,
                                                validate: ValidateSQLInjection
                                            }   
                                            )
                                        } 
                                        onKeyUp={handleChange} onBlur={checkPin} error={errors.pincode}  defaultValue={st.customerDetails.pincode && st.customerDetails.pincode}
                                        style={{width:'100%'}} label="Pincode" variant="filled" InputProps={{disableUnderline:true}} 
                                    />
                                    {errors.pincode && <FormHelperText error style={{color:'#F37512'}}>{errors.pincode.message}</FormHelperText>}

                                    {/*
                                        --------------- AREA ---------------
                                    */}
                                    <CustomFormControl style={{ width: '100%' }}>
                                        <CustomInputLabel style={{ paddingTop: '28px' }}>Pincode Post Office Area</CustomInputLabel>
                                        <CustomSelect
                                            name="area"
                                            {...register('area', { required: 'Please select an area' })}
                                            onKeyUp={handleChange}
                                            onChange={selectArea}
                                            value={st.customerDetails.area ? st.customerDetails.area : selectedOption}
                                            variant="filled"
                                            InputProps={{ disableUnderline: true }}
                                            error={errors.area} // Add the error prop to display the validation error
                                        >
                                            {
                                                st.customerDetails.area
                                                &&
                                                <MenuItem value={st.customerDetails.area}>
                                                    {st.customerDetails.area}
                                                </MenuItem>
                                            }
                                            {
                                                area.map((item, index) => (
                                                    <MenuItem key={index} value={item.area}>
                                                        {item.area}
                                                    </MenuItem>
                                                ))
                                            }
                                        </CustomSelect>

                                        {errors.area && (
                                            <FormHelperText error>{errors.area.message}</FormHelperText>
                                        )}
                                    </CustomFormControl>

                                    {/*
                                        --------------- DISTRICT ---------------
                                    */}
                                    <CustomTextField 
                                        name="district_tmp" 
                                        onKeyUp={handleChange} value={dist} error={errors.district_tmp} defaultValue={dist_form}
                                        style={{width:'100%'}} label="District (Fetched from Pincode)" variant="filled" InputProps={{disableUnderline:true}} 
                                    />


                                    {/*
                                        --------------- STATE ---------------
                                    */}
                                    <CustomTextField 
                                        name="state" 
                                        {...register('state')}
                                        onKeyUp={handleChange} value={state} error={errors.state} defaultValue={state_form}
                                        style={{width:'100%'}} label="State (Fetched from Pincode)" variant="filled" InputProps={{disableUnderline:true}} 
                                    />

                                    {/*
                                        --------------- ADDRESS 1 ---------------
                                    */}
                                    <CustomTextField 
                                        name="address_line_1" 
                                        {...register(
                                            'address_line_1', 
                                            {
                                                required:"This field is mandatory",
                                                validate: ValidateSQLInjection
                                            }   
                                            )
                                        } 
                                        onKeyUp={handleChange} error={errors.address_line_1}  defaultValue={st.customerDetails.address_line_1 && st.customerDetails.address_line_1}
                                        style={{width:'100%'}} label="Flat / Home / Building" variant="filled" InputProps={{disableUnderline:true}} 
                                    />
                                    {errors.address_line_1 && <FormHelperText error style={{color:'#F37512'}}>{errors.address_line_1.message}</FormHelperText>}

                                    {/*
                                        --------------- ADDRESS 2 ---------------
                                    */}
                                    <CustomTextField 
                                        name="address_line_2" 
                                        {...register(
                                            'address_line_2', 
                                            {
                                                required:"This field is mandatory",
                                                validate: ValidateSQLInjection
                                            }   
                                            )
                                        } 
                                        onKeyUp={handleChange} error={errors.address_line_2}  defaultValue={st.customerDetails.address_line_2 && st.customerDetails.address_line_2}
                                        style={{width:'100%'}} label="Address Line 2" variant="filled" InputProps={{disableUnderline:true}} 
                                    />
                                    {errors.address_line_2 && <FormHelperText error style={{color:'#F37512'}}>{errors.address_line_2.message}</FormHelperText>}



                            </div>

                            <div className="stdMargin" style={{margin:'0px 30px 0px'}}>
                            {
                                Object.keys(errors).length === 0
                                ?
                                <div type='submit' style={{marginTop:'23px'}} onClick={handleSubmit(onSubmit)} className="stdButton">Proceed</div>
                                :
                                <div>
                                    <div disabled type='submit' style={{marginTop:'23px'}} className="stdButtonDisabled">Proceed</div>
                                    {/*}<div style={{color:'#888',paddingTop:'10px'}}>Ensure all fields are correct above</div>{*/}
                                </div>
                            }
                            </div>


                        </form>

                    </div>

                </div>

            </div>
        </div>
        <ViewCart />
    </div>
}

export default UserDetails