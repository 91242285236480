


import { useForm } from 'react-hook-form';

// MUI
import { CustomTextField } from "../mui/text";
import { FormHelperText } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

// Other
import fetchData from '../api/api';
import md5 from 'md5';

// Form Validations
import { MobileValidation, ValidateSQLInjection } from "../mui/validations"

const Login = () =>{

    // Primary Components
    const { register, handleSubmit, formState: { errors }, trigger } = useForm();
    const dispatch = useDispatch();
    const inputRef = useRef();

    // Secondary Components
    const [auth,setAuth] = useState(1)
    const [authtype,setauthtype] = useState("PASSWORD")
    const [error,seterror] = useState("")

    // Business Methods
    useEffect(()=>{
        dispatch({type:'BACKDROP_OFF'})
    },[dispatch])

    const handleChange = async (event) => {
        await trigger(event.target.name);
    };
    const onSubmit = (data) => {
        seterror(data)

        dispatch({type:'BACKDROP_ON'})
        const getData = async (mobile) => {
            var uri = 'api/v1.0/public/auth1';
            var body = {
                "login":mobile
            };
            const data = await fetchData(uri,body);
            try{
                if (data) {
                    setauthtype(data.data.type)
                    if((data.data.type==="PASSWORD") || (data.data.type==="OTP")){
                        setAuth(2)
                    }
                    else{
                        dispatch({type:'DIALOG_ON',title:'Unregistered Mobile',body:'Mobile is not registered, kindly try correct number or register yourself.'})
                    }
                }
                dispatch({type:'BACKDROP_OFF'})
            }
            catch (error) {
                dispatch({type:'CHANGE_BODY',payload:'Maintenance'})
            }
        };
        getData(data.mobile);
    };
    const onSubmitOtp = (data) => {
        
        var challenge="";
        if(authtype==="PASSWORD")
            challenge = md5(data.password)
        else 
            challenge = (data.OTP)
        
        dispatch({type:'BACKDROP_ON'})
        const getData = async (mobile,challenge,authtype) => {
            var uri = 'api/v1.0/public/auth2';
            var body={
                "type":authtype,
                "challenge":challenge,
                "login":mobile
            }
            const data = await fetchData(uri,body);
            try{
                if (data) {
                    
                    if(data.status==="fail"){
                        seterror("Fail")
                        dispatch({type:'LOGOFF'})
                    }
                    else{
                        seterror("Pass")
                        /*dispatch({type:'LOGIN',payload:data.data.dbData[0]})*/
                        dispatch({type:'LOGIN',payload:data.data})
                        dispatch({type:'CUSTOMER_DETAILS',payload:data.data,usertype:'password'})
                        dispatch({type:'DIALOG_ON',title:'Login Successful',body:'Your credentials are successfully authenticated'})
                        dispatch({type:'CHANGE_BODY',payload:'Home'})
                    }
                }
                dispatch({type:'BACKDROP_OFF'})
            }
            catch (error) {
                dispatch({type:'CHANGE_BODY',payload:'Maintenance'})
            }
        };
        getData(data.mobile,challenge,authtype);


        //setAuth(1)
    };
    const RegisterUser = () =>{
        dispatch({type:'BACKDROP_ON'})
        dispatch({type:'CHANGE_BODY',payload:'UserDetails'})
    }
    return <div>
        <div style={{display:'flex',justifyContent:'center'}}>
            {
                auth===1
                ?
                <div className="stdPadding stdMargin" style={{background:'#fff',width:'450px'}}>
                    <form onSubmit={handleSubmit(onSubmit)}>

                        
                        <h2 >Login Authentication</h2>
                        <div className="stdMargin" style={{margin:'10px 10px 0px'}}>
                            {/*
                                --------------- MOBILE ---------------
                            */}
                            <CustomTextField 
                                name="mobile" 
                                {...register(
                                    'mobile', 
                                    {
                                        required:"This field is mandatory",
                                        pattern: MobileValidation,
                                        validate: ValidateSQLInjection
                                    }
                                    )
                                } 
    
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">+91</InputAdornment>,
                                    disableUnderline:true
                                }}
                                onKeyUp={handleChange} error={errors.mobile} 
                                style={{width:'100%'}} label="Enter Mobile Number" variant="filled"
                            />
                            {errors.mobile && <FormHelperText error style={{color:'#F37512'}}>{errors.mobile.message}</FormHelperText>}

                            {errors.OTP && <FormHelperText error style={{color:'#F37512'}}>{errors.OTP.message}</FormHelperText>}

                            {
                                Object.keys(errors).length === 0
                                ?
                                <div type='submit' style={{marginTop:'23px'}} onClick={handleSubmit(onSubmit)} className="stdButton">Proceed</div>
                                :
                                <div>
                                    <div disabled type='submit' style={{marginTop:'23px'}} className="stdButtonDisabled">Proceed</div>
                                    {/*}<div style={{color:'#888',paddingTop:'10px'}}>Ensure all fields are correct above</div>{*/}
                                </div>
                            }

                            
                            <div onClick={()=>RegisterUser()} style={{cursor:'pointer',color:'#555',marginTop:'10px'}}>Sign Up</div>
                        </div>
                    </form>
                </div>
                :
                
                    authtype==="PASSWORD"
                    ?
                    <div className="stdPadding stdMargin" style={{background:'#fff',width:'450px'}}>
                        <form onSubmit={handleSubmit(onSubmitOtp)}>
                            <h2 >Enter PASSWORD</h2>
                            <div className="stdMargin" style={{margin:'10px 10px 0px'}}>
                                {/*
                                    --------------- PASSWORD ---------------
                                */}
                                
                                <CustomTextField 
                                    name="password" 
                                    type="password"
                                    inputRef={inputRef}
                                    {...register(
                                        'password', 
                                        {
                                            required:"This field is mandatory"
                                        }
                                        )
                                    } 
        
                                    InputProps={{
                                        disableUnderline:true,
                                        style: {
                                          borderRadius: '0px',
                                        },
                                    }}
                                    onKeyUp={handleChange} error={errors.password} 
                                    style={{width:'100%'}}
                                />
                                {errors.password && <FormHelperText error style={{color:'#F37512'}}>{errors.password.message}</FormHelperText>}
                                {error==="Fail" && <FormHelperText error style={{color:'#F37512'}}>Authentication Failed, please enter correct password.</FormHelperText>}
                                {error==="Pass" && <FormHelperText error style={{color:'GREEN'}}>Authentication Succeeded</FormHelperText>}
                                {errors.OTP && <FormHelperText error style={{color:'#F37512'}}>{errors.OTP.message}</FormHelperText>}


                                {
                                    Object.keys(errors).length === 0
                                    ?
                                    <div type='submit' style={{marginTop:'23px'}} onClick={handleSubmit(onSubmitOtp)} className="stdButton">Proceed</div>
                                    :
                                    <div>
                                        <div disabled type='submit' style={{marginTop:'23px'}} className="stdButtonDisabled">Proceed</div>
                                        {/*}<div style={{color:'#888',paddingTop:'10px'}}>Ensure all fields are correct above</div>{*/}
                                    </div>
                                }
                                
                            </div>
                        </form>
                    </div>
                    :
                    <div className="stdPadding stdMargin" style={{background:'#fff',width:'450px'}}>
                        <form onSubmit={handleSubmit(onSubmitOtp)}>
                            <h2 >Enter OTP</h2>
                            <div className="stdMargin" style={{margin:'10px 10px 0px'}}>
                                {/*
                                    --------------- OTP ---------------
                                */}
                                <input 
                                    name="OTP" 
                                    {...register(
                                        'OTP', 
                                        {
                                            validate: ValidateSQLInjection
                                        }
                                        )
                                    } 
                                    onKeyUp={handleChange} error={errors.OTP} 
                                    maxLength="4"
                                    style={{
                                        width: 'calc(100% - 20px)',
                                        fontSize: '30px',
                                        textAlign: 'center',
                                        textDecoration: 'none',
                                        border: '1px solid #ccc',
                                        padding: '10px',
                                        letterSpacing: '5'
                                    }}
                                
                                />
                                {errors.OTP && <FormHelperText error style={{color:'#F37512'}}>{errors.OTP.message}</FormHelperText>}
                                {error==="Fail" && <FormHelperText error style={{color:'#F37512'}}>Incorrect OTP</FormHelperText>}
                                {error==="Pass" && <FormHelperText error style={{color:'GREEN'}}>Authentication Succeeded</FormHelperText>}

                                {
                                    Object.keys(errors).length === 0
                                    ?
                                    <div type='submit' style={{marginTop:'23px'}} onClick={handleSubmit(onSubmitOtp)} className="stdButton">Proceed</div>
                                    :
                                    <div>
                                        <div disabled type='submit' style={{marginTop:'23px'}} className="stdButtonDisabled">Proceed</div>
                                        {/*}<div style={{color:'#888',paddingTop:'10px'}}>Ensure all fields are correct above</div>{*/}
                                    </div>
                                }
                                
                            </div>
                        </form>
                    </div>
                
            }



        </div>

    </div>
}

export default Login

/*


                            <CustomTextField 
                                name="OTP" 
                                {...register(
                                    'OTP', 
                                    {
                                        validate: validateSQLInjection
                                    }
                                    )
                                } 
                                defaultValue={dataOTP} // Set initial value
                                value={watch('OTP')} // Use watch from react-hook-form to capture the current value
                                onChange={(e) => setValue('OTP', e.target.value)} // Use setValue from react-hook-form to update the value
                                InputProps={{
                                    disableUnderline:true
                                }}
                                onKeyUp={handleChange} error={errors.OTP} 
                                style={{width:'100%'}} label="Enter OTP" variant="filled"
                            />

*/